import React, { Component } from 'react';
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  format,
  addDays,
  startOfMonth,
  endOfMonth,
  isSameDay,
  startOfISOWeek,
} from "date-fns";
import {
  Grid,
  Button,
  ButtonGroup,
  TextField,
  withStyles,
  LinearProgress,
  Box,
  Typography,
  Snackbar,
  Tooltip,
  makeStyles,
} from "@material-ui/core";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import amber from "@material-ui/core/colors/amber";
import Footer from "./Footer";
import BackDatedMonthToggle from "./BackDatedMonthToggle";
import BackDatedSingleTable from "./BackDatedSingleTable";
import "./Timesheet.css";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const useStyles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    textAlign: "center",
    "&:disabled": {
      color: "#212121",
    },
  },
  pending: {
    flexGrow: 1,
    ...theme.typography.button,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(0.5),
  },
  greet: {
    flexGrow: 1,
    fontSize: "1rem",
    fontWeight: 500,
    lineHeight: 1.75,
    letterSpacing: "0.02857em",
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(0.5),
  },
  backdropStyle: {
    zIndex: theme.zIndex.drawer + 1,
    color: "white",
    height: "120%",
    width: "120%",
    //position: "relative",
  },
});

const CustomTextField = withStyles({
  root: {
    '& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]': {
      MozAppearance: 'textfield', // For Firefox
    },
  },
})(TextField);

    
class BackdatedTimesheet extends Component {
    constructor(props) {
      super(props);  
      this.projectFilterRef = React.createRef();

      this.state = {
        empList: [],
        empSelected: null,
        isLoading: true,
        timesheetData: [],

        // for storing all the invoice and
        // activity id for leave and holiday
        leave: {},

        // for storing all rows data (for left table)
        rows: [],

        //storing current day, lastmonday etc for clientquery
        client_query_disabled_dates: {
          formatLastMonday: "",
          formatToday: "",
        },

        // for storing days headers and
        // hour data according to month
        daysHeader: [],
        dayRows: [],
        rejectedDates: {},
        totalDateHours: {},
        prevDateValues: {},
        allDatesValues: {},
        allDatesMatrix: {},

        //for storing client response
        clientResponse: [],

        // for separate alert of holidays
        holiDates: {},

        // right click popup variables
        description: "",
        clientQuery: false,
        invalidDescLength: false,
        allDatesDescriptions: {},
        allDatesClientQuery: {},
        currSelectedDate: "",
        currSelectedCell: "",
        currSelectedValue: "",

        // validation for total hours
        invalidTtlHours: false,

        // for storing employee details
        employee: {},
        currDate: new Date(localStorage.getItem("date")),

        // for storing footer info
        applicable: 0,
        clocked: 0,
        leaves: 0,
        holidays: 0,
        joining: "",

        pending: [],
        currentTable: "",
        progress: 0,
        configData:[],

        // alerts and dialog
        noChange: false,
        submitAlert: false,
        totalHourAlert: false,
        leaveHourAlert: false,
        totalHoliHourAlert: false,
        fridayAlert: false,
        clientValueSubmit: false,
        clientValueAlert: false,
        descriptionDialog: false,
        fillHourAlert: false,
        configDataByName: {}
      }

      this.onEmployeeChange = this.onEmployeeChange.bind(this)
      this.handleMonthToggle = this.handleMonthToggle.bind(this);
      this.fetchHolidays = this.fetchHolidays.bind(this);
      this.fetchFooterInfo = this.fetchFooterInfo.bind(this);
      this.fetchTimesheetConfigPost = this.fetchTimesheetConfigPost.bind(this)
      this.fetchTimesheetConfigByNamePost = this.fetchTimesheetConfigByNamePost.bind(this)
      this.setData = this.setData.bind(this);
      this.checkTimesheet = this.checkTimesheet.bind(this);
      this.fetchCombinationDaysPost = this.fetchCombinationDaysPost.bind(this)
      this.postHours = this.postHours.bind(this);
      this.updateHours = this.updateHours.bind(this);
      this.handleHourChange = this.handleHourChange.bind(this);
      this.makeRequests = this.makeRequests.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.submitClientResHandler = this.submitClientResHandler.bind(this);
      this.handleNoChange = this.handleNoChange.bind(this);
      this.handleSubmitAlert = this.handleSubmitAlert.bind(this);
      this.handleHourAlert = this.handleHourAlert.bind(this);
      this.handleLeaveHourAlert = this.handleLeaveHourAlert.bind(this)
      this.handleHoliHourAlert = this.handleHoliHourAlert.bind(this);
      this.handleFillHourAlert = this.handleFillHourAlert.bind(this);
      this.handleFridayValueAlert = this.handleFridayValueAlert.bind(this);
      this.handleFridaySubmitAlert = this.handleFridaySubmitAlert.bind(this);
      this.postClientValue = this.postClientValue.bind(this);
      this.handleFridaySubmit = this.handleFridaySubmit.bind(this);
      this.getDaysHeader = this.getDaysHeader.bind(this);
      this.getDays = this.getDays.bind(this);
      this.showDialog = this.showDialog.bind(this);
      this.closeDialog = this.closeDialog.bind(this);
      this.handleDialogChange = this.handleDialogChange.bind(this);
      this.submitDialog = this.submitDialog.bind(this);
      this.changeState = this.changeState.bind(this);
      this.renderTimesheet = this.renderTimesheet.bind(this);
      this.getClientReposne = this.getClientReposne.bind(this)
      
  }
  
  onEmployeeChange(e, val) { 
    if (val !== null) {
      this.setState({ empSelected: val, totalDateHours: {}, isLoading: true },
        async () => {

          var tmsData = await this.fetchTimesheetDataPost()
          this.setState({ timesheetData: tmsData })
       
          var date = localStorage.getItem("date");
          var currMonth = format(new Date(date), "M");
          var currYear = format(new Date(date), "yyyy");
      
          var country = this.state.empSelected.holiday_calendar_id === "" ? 1 : this.state.empSelected.holiday_calendar_id;

          var footerInfoObj = await this.fetchFooterInfo(currMonth, currYear, country);
          var footerInfo = footerInfoObj.data
               
          await this.setData(
            this.state.currDate,
            this.state.empSelected.employee_code,
            footerInfo[4]
          );
        })
    }
    else { 
      this.setState({empSelected:val})
    }
  }

  // send client response status to backend
  async postClientQueryResponse(clientQueryResponse) {
    return fetch(
      `${process.env.REACT_APP_TIMESHEET_DJANGO_URL}/timesheet/backdated-client-response/${this.state.empSelected.employee_code}/`,
      {
        // mode: 'no-cors',
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({ "clientResponse": clientQueryResponse }),
      }
    ).then((res) => res);
  }

  // get client response
  async getClientReposne() {
    return fetch(
      `${process.env.REACT_APP_TIMESHEET_DJANGO_URL}/timesheet/backdated-client-response/${this.state.empSelected.employee_code}/`,
      {
        // mode: 'no-cors',
        method: "GET",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    ).then((res) => res.json());
  }

  // fetch holidays applicable to the user for current month, year
  async fetchHolidays(country, month, year) {
    return fetch(
      `${process.env.REACT_APP_TIMESHEET_DJANGO_URL}/timesheet/holiday/${country}/${month}/${year}/`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    ).then((res) => res.json());
  }

  // fetch all hour type details on footer
  async fetchFooterInfo(month, year, country) {
    return fetch(
      `${process.env.REACT_APP_TIMESHEET_DJANGO_URL}/timesheet/parameter-value/${month}/${year}/${country}/`,
      {
        method: "POST",
        body: JSON.stringify({"date":localStorage.getItem('date'), "empCode":this.state.empSelected.employee_code, "joiningDate": this.state.empSelected.date_of_joining, "type": this.state.empSelected.holiday_calendar_id}),
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    ).then((res) => res.json());
  }

  async fetchTimesheetConfigPost() {
    return fetch(
      `${process.env.REACT_APP_TIMESHEET_DJANGO_URL}/timesheet/config-value/abcd/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({ "config_ids": [1, 2, 3] })
      }
    ).then((res) => res.json());
  }
 

  async fetchTimesheetConfigByNamePost() {
    return fetch(
      `${process.env.REACT_APP_TIMESHEET_DJANGO_URL}/timesheet/config-value-by-name/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({ "config_names": ["Total Hours in Day"] })
      }
    ).then((res) => res.json());
  }

  // POST API to fetch users timesheet invoices/activities
  async fetchTimesheetDataPost() {
    return fetch(
      `${process.env.REACT_APP_TIMESHEET_DJANGO_URL}/timesheet/backdated-emp-inv-activity/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({"date":localStorage.getItem('date'), "empCode": this.state.empSelected.employee_code})
      }
    ).then((res) => res.json());
  }

  // fetch all hour data for all project-activity combination
  async setData(currDate, code, joining) {
    var currMonth = format(currDate, "M");
    var currYear = format(currDate, "yyyy");
    var country = this.state.empSelected.holiday_calendar_id === ""? 1 : this.state.empSelected.holiday_calendar_id;
    var holidayDates = await this.fetchHolidays(country, currMonth, currYear);

    var footerInfoObj = await this.fetchFooterInfo(currMonth, currYear, country);
    var footerInfo = footerInfoObj.data
    var joining_date = new Date(footerInfo[4]);
    var totalHours = this.state.totalDateHours;
    var dateKeys = Object.keys(totalHours);
    if (
      joining_date.getMonth() === currMonth &&
      joining_date.getFullYear() === currYear
    ) {
      for (let i = 0; i < joining_date.getDate() - 1; i++) {
        totalHours[dateKeys[i]] = 0;
      }
    }

    var clockedHours = footerInfo[0],
      leaveHours = footerInfo[1];
    var holidayHours = footerInfo[2],
      pendingDates = footerInfo[3];

    ///////////////////////////////////  NEW CODE TO FETCH TIMESHEET CONFIG /////////////////////////////////
    var configData = await this.fetchTimesheetConfigPost()
    var numWeeks = parseInt(configData.filter(obj => obj.id == 1)[0]['value'])
    var currMonthAllowDay = configData.filter(obj => obj.id == 2)[0]['value']
    var prevMonthAllowWeeks = configData.filter(obj => obj.id == 3)[0]['value']
    this.setState({configData:configData})
    //////////////////////////////////////// END ///////////////////////////////////////////////////////////

    /////////////////////////////////// Fetch Total Hours in Day  //////////////////////////////////////////

    var configDataByName = await this.fetchTimesheetConfigByNamePost()

    //////////////////////////////////////// END //////////////////////////////////////////////////////////

    /////////////////////// GET Client Response //////////////////////////////////////////////////

    let clientRes = await this.getClientReposne()

    /////////////////////////////// END ////////////////////////////////////////

    var days = [];
    var headers = this.getDaysHeader(currDate, holidayDates, joining_date);
    var timesheetData = this.state.timesheetData;
    var row = {},
      rows = [];

    var hourTotal = 0,
      count = 0;
    for (let i = 0; i < timesheetData.length; i++)
      hourTotal += timesheetData[i]["activities"].length;

    /////// Fetch user's timesheet/hour data in one go  to avoid calling APIS in loop///////
    var usersTimesheetData = { "code": code, "currMonth": currMonth, "currYear": currYear }
    var proIdArr = []
    var actIdArr = []
    for (let i = 0; i < timesheetData.length; i++) {
      for (let j = 0; j < timesheetData[i]["activities"].length; j++) {
        if (localStorage.getItem("page") !== "approver") {
          var pro_id = timesheetData[i]["invoice_id"],
            act_id = timesheetData[i]["activities"][j]["id"];
          proIdArr.push(pro_id)
          actIdArr.push(act_id)
        }
      }
    }

    usersTimesheetData['pro_id'] = proIdArr
    usersTimesheetData['act_id'] = actIdArr
    //console.log(usersTimesheetData, ' ----------')

    var usersHoursData = await this.fetchCombinationDaysPost(usersTimesheetData)

    //////////////////////// END ////////////////////////////////////////////////////////////////

    for (let i = 0; i < timesheetData.length; i++) {
      for (let j = 0; j < timesheetData[i]["activities"].length; j++) {
        if (localStorage.getItem("page") !== "approver") {
          var pro_id = timesheetData[i]["invoice_id"],
            act_id = timesheetData[i]["activities"][j]["id"];
          var id = parseInt(`${pro_id}${act_id}`);
          var isRemoved = timesheetData[i]["activities"][j]["is_removed"]

          ///////////////////////////////////////////// NEW CODE /////////////////////////////////////
          var hourData = usersHoursData.filter((obj) => obj.employee_code == code && obj.activity == act_id && obj.invoice == pro_id)
          //////////////////////////////////////  END ///////////////////////////////////////////////
          var d = this.getDays(
            hourData,
            pro_id,
            act_id,
            currDate,
            holidayDates,
            numWeeks,
            currMonthAllowDay,
            prevMonthAllowWeeks,
            isRemoved,
            pendingDates
          );
          var total = 0;
          for (let j = 0; j < hourData.length; j++) {
            total += parseFloat(hourData[j].hour);
          }

          var status = "Approved";
          for (let j = 0; j < hourData.length; j++) {
            if (hourData[j].status === -1) {
              status = "Rejected";
              break;
            }
          }

          days.push(<TableRow key={id}>{d}</TableRow>);

          row = {
            id: id,
            code: code,
            client: timesheetData[i]["client_name"],
            pro_id: pro_id,
            project: timesheetData[i]["invoice_name"],
            act_id: act_id,
            activity: timesheetData[i]["activities"][j]["activity_name"],
            total: total,
            approval: status,
            days: d,
            isRemoved: isRemoved
          };
          rows.push(row);
          count++;
        }
      }
    }
    this.setState({
        rows: rows,
        daysHeader: headers,
        dayRows: days,
        currDate: currDate,
        clocked: clockedHours,
        leaves: leaveHours,
        holidays: holidayHours,
        pending: pendingDates,
        holiDates: holidayDates,
        isLoading: false,
        configDataByName: configDataByName
      }, () => {
        this.renderTimesheet(joining);
      });

    this.projectFilterRef.current.monthToggleHandler(this.state.rows);
    this.projectFilterRef.current.fetchClientResponse(clientRes)
  }

  // fetch hour data for a specific project-activity combination in one go POST
  async fetchCombinationDaysPost(usersTimesheetData) {
    return fetch(
      `${process.env.REACT_APP_TIMESHEET_DJANGO_URL}/timesheet/timesheet-activity-invoice-list-month/code/1/1/1/1/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(usersTimesheetData),
      }
    ).then((res) => res.json());
  }


  // check if there is a record for a specific project-activity on a given date
  async checkTimesheet(pro, act, date) {
    return fetch(
      `${process.env.REACT_APP_TIMESHEET_DJANGO_URL}/timesheet/backdated-timesheet-check/${pro}/${act}/${date}/${this.state.empSelected.employee_code}/`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    ).then((res) => res.json());
  }

  // request to create a new timesheet record
  async postHours(dateObj) {
    dateObj.hour = parseFloat(dateObj.hour);
    // console.log("posthours", dateObj);
    await fetch(
      `${process.env.REACT_APP_TIMESHEET_DJANGO_URL}/timesheet/backdated-timesheet-list/`,
      {
        // mode: 'no-cors',
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(dateObj),
      }
    );
  }

  // request to update a new timesheet record
  async updateHours(dateObj) {
    dateObj.hour = parseFloat(dateObj.hour);
    
    await fetch(
      `${process.env.REACT_APP_TIMESHEET_DJANGO_URL}/timesheet/backdated-timesheet-details/${dateObj.id}/`,
      {
        // mode: 'no-cors',
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(dateObj),
      }
    );
  }
  // handle state changes on typing in any cell
  handleHourChange(e) {
    var name = e.target.name;
    var value = e.target.value;

    value = Number(value);
    value = value.toFixed(2);

    /// get total hours of the day
    var totalHrsHoursInDay = parseFloat(this.state.configDataByName['Total Hours in Day'])

    value = value === "" ? 0 : parseFloat(value);

    var element = document.getElementById(name);
    var date = element.dataset.date;
    var newTotal = this.state.totalDateHours[date];
    if (name.includes("/") && this.state.allDatesValues[name] === 0) {
      newTotal += value;
      // console.log("New Total", newTotal);
      // validation for total hours
      if (newTotal > totalHrsHoursInDay || newTotal < 0) {
        this.setState({ totalHourAlert: true, invalidTtlHours: true });
      } else {
        this.setState({ totalHourAlert: false, invalidTtlHours: false });
      }
    } else {
      newTotal += value - this.state.allDatesValues[name];
      // console.log("New Total", newTotal);
      // validation for total hours
      if (newTotal > totalHrsHoursInDay || newTotal < 0) {
        this.setState({ totalHourAlert: true, invalidTtlHours: true });
      } else {
        this.setState({ totalHourAlert: false, invalidTtlHours: false });
      }
    }
    
    // Leave Hour Logic 
    if (name.endsWith('/a125')) {
      if (value > 8) {
        this.setState({ leaveHourAlert: true, invalidTtlHours: true })
      }
      else {
        this.setState({ leaveHourAlert: false, invalidTtlHours: false })
      }
    }
    
    this.setState({
      allDatesMatrix: {
        ...this.state.allDatesMatrix,
        [name]: value,
      },
      allDatesValues: {
        ...this.state.allDatesValues,
        [name]: value,
      },
      totalDateHours: {
        ...this.state.totalDateHours,
        [date]: newTotal,
      },
    });
  }
  
  // make requests for all new/updated entries
  async makeRequests() {
    var allDates = this.state.allDatesMatrix;
    var keys = Object.keys(allDates);

    var dateObj = {},
      changes = false,
      updateList = [];

      if (!this.state.invalidTtlHours) {
        // Step 1: Loop through the keys and calculate the difference between current and previous values
        for (let i = 0; i < keys.length; i++) {
            var element = document.getElementById(keys[i]);
            var status = parseInt(element.dataset.status);
            var value = allDates[keys[i]];
            var prevValue = this.state.prevDateValues[keys[i]];
            var description = this.state.allDatesDescriptions[keys[i]];
            var clientQuery = this.state.allDatesClientQuery[keys[i]];
    
            // Calculate the difference between the current and previous value
            var difference = value - prevValue;
    
            // Step 2: Add the entry to a list, including the difference
            updateList.push({
                key: keys[i],
                value: value,
                prevValue: prevValue,
                difference: difference, // Store the difference for sorting
                status: status,
                description: description,
                clientQuery: clientQuery,
                element: element, 
            });
        }
    
        // Step 3: Sort the updateList based on the `difference` (most negative first)
        updateList.sort((a, b) => a.difference - b.difference);
    
        // Step 4: Process each item in the sorted order
        for (let i = 0; i < updateList.length; i++) {
            var entry = updateList[i];
            var element = entry.element;
            var difference = entry.difference;
            var value = entry.value;
            var prevValue = entry.prevValue;
            var status = entry.status;
            var description = entry.description;
            var clientQuery = entry.clientQuery;
            var key = entry.key;
    
            // POST method for new entries
            if (key.includes("/") && value > 0) {
                changes = true;
                var pro_id = key.split("/p")[1].split("/")[0];
                var act_id = key.split("/a")[1];
                var date = key.split("/")[0];
                var existing_id = await this.checkTimesheet(pro_id, act_id, date);
    
                if (existing_id === -1) {
                    // console.log("post query");
                    dateObj = {
                        date: date,
                        hour: value,
                        activity: act_id,
                        status: 0,
                        description: description,
                        is_client_query: clientQuery,
                        datedim: date,
                        employee_code: this.state.empSelected.employee_code,
                        invoice: pro_id,
                    };
                    await this.postHours(dateObj);
                } else {
                    // console.log("update query");
                    dateObj = {
                        id: existing_id,
                        hour: value,
                        status: 0,
                        description: description,
                        is_client_query: clientQuery,
                        update_date: format(new Date(), "yyyy-MM-dd HH:mm:ss.SSSSSS"),
                    };
                    await this.updateHours(dateObj);
                }
            }
            // PUT method for updates
            else if (prevValue !== value) {
                changes = true;
                dateObj = {
                    id: parseInt(key),
                    hour: value,
                    status: status === -1 || status === 1 ? 1 : status,
                    description: description,
                    is_client_query: clientQuery,
                    update_date: format(new Date(), "yyyy-MM-dd HH:mm:ss.SSSSSS"),
                };
    
                await this.updateHours(dateObj);
    
                if (status === 0 && element.style.background !== "")
                    element.style.background = "white";
                else if (status !== 0) 
                 element.style.background = amber[400];
            }
        }
      }
       return changes;
      }    

  // handle all changes and send to backend on submit button click
  async handleSubmit(e) {
    this.setState({ isLoading: true });
    
    ///////////////// COMMENTED DUE TO ISSUE IN ORACLE ///////////////////////
    // post Client Query response status
    
    
    var temp_res_changes = await this.postClientQueryResponse(
      this.state.clientResponse
    );
    let res_changes = false;
    if (temp_res_changes.status === 201) {
      res_changes = true;
    }
    

    ////////////////////// END COMMENT //////////////////////////////////////////

    var changes = await this.makeRequests();

    this.setState({
      totalDateHours: {},
    });
    await this.setData(this.state.currDate, this.state.empSelected.employee_code);
    this.projectFilterRef.current.dropdownChangeHandler();

    localStorage.setItem("date", format(this.state.currDate, "yyyy-MM-dd"));

    var date = new Date(this.state.currDate);
    var currMonth = format(date, "M");
    var currYear = format(date, "yyyy");
    var country = this.state.empSelected.holiday_calendar_id === ""? 1 : this.state.empSelected.holiday_calendar_id;
    var footerInfoObj = await this.fetchFooterInfo(currMonth, currYear, country);
    var footerInfo = footerInfoObj.data
    var clockedHours = footerInfo[0],
      leaveHours = footerInfo[1];
    var holidayHours = footerInfo[2],
      pendingDates = footerInfo[3];

    this.setState(
      {
        clocked: clockedHours,
        leaves: leaveHours,
        holidays: holidayHours,
        pending: pendingDates,
      },
      async function () {
        if (!changes && !res_changes)
          this.setState({ noChange: true, is_submit_loading: false });
        else {
          this.setState({
            submitAlert: true,
            allDatesMatrix: {},
            leaves: leaveHours,
            holidays: holidayHours,
            pending: pendingDates,
            clocked: clockedHours,
            isLoading: false,
          });
        }
        this.renderTimesheet();

      }
    );
  }

  // client query response handler
  submitClientResHandler(res) {
    this.setState({ clientResponse: res });
  }

  // close no changes alert
  handleNoChange(event, reason) {
    if (reason === "clickaway") return;
    this.setState({ noChange: false });
  }

  // close submit alert
  handleSubmitAlert(event, reason) {
    if (reason === "clickaway") return;
    this.setState({ submitAlert: false });
  }

  // close working day hour alert of (8-16)
  handleHourAlert(event, reason) {
    if (reason === "clickaway") return;
    this.setState({ totalHourAlert: false });
  }

  // Leave hour alert
  handleLeaveHourAlert(event, reason) {
    if (reason === "clickaway") return;
    this.setState({ leaveHourAlert: false });
  }

  // close holiday hour alert of (<16)
  handleHoliHourAlert(event, reason) {
    if (reason === "clickaway") return;
    this.setState({ totalHoliHourAlert: false });
  }

  // close fill hour first alert when right click
  handleFillHourAlert(event, reason) {
    if (reason === "clickaway") return;
    this.setState({ fillHourAlert: false });
  }

  // close client value feedback less than 20 chars alert
  handleFridayValueAlert(event, reason) {
    if (reason === "clickaway") return;
    this.setState({ clientValueAlert: false });
  }

  // close client value feedback submitted alert
  handleFridaySubmitAlert(event, reason) {
    if (reason === "clickaway") return;
    this.setState({ clientValueSubmit: false });
  }

  // send client value feedback to backend
  async postClientValue(clientObj, date) {
    fetch(
      `${process.env.REACT_APP_TIMESHEET_DJANGO_URL}/timesheet/client-value/${date}/`,
      {
        // mode: 'no-cors',
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(clientObj),
      }
    );
  }

  // handle and submit friday alert details
  async handleFridaySubmit() {
    var value = document.getElementById("client-value").value;
    if (value.length < 20) this.setState({ clientValueAlert: true });
    else {
      var date = format(new Date(), "yyyy-MM-dd");
      var clientObj = {
        employee_code: this.state.empSelected.employee_code,
        client_value: value,
        date: date,
      };

      // console.log("client value: ", clientObj);

      await this.postClientValue(clientObj, date);
      this.setState({
        clientValueSubmit: true,
        fridayAlert: false,
      });
    }
  }

  // get days headers with specific colors for holidays/current day
  getDaysHeader(currDate, holidayDates, joining_date) {
    const monthStart = startOfMonth(currDate);
    const monthEnd = endOfMonth(monthStart);
    let days = [];
    let day = monthStart;
    var holi = 0,
      totalDays = 0;

    var color = "white";
    while (day <= monthEnd) {
      const cloneDay = format(day, "d");
      const weekDay = format(day, "EEE");
      var date = format(day, "yyyy-MM-dd");

      if (holidayDates.includes(date)) {
        color = "#ededed";
        holi++;
      }

      if (isSameDay(day, new Date())) {
        color = "#4DBDC1";
      }

      if (isSameDay(day, new Date())) {
        days.push(
          <TableCell
            id="todayHeader"
            align="center"
            style={{ background: color }}
          >
            {cloneDay}
            <br />({weekDay})
          </TableCell>
        );
      } else {
        days.push(
          <TableCell align="center" style={{ background: color }}>
            {cloneDay}
            <br />({weekDay})
          </TableCell>
        );
      }

      color = "white";
      day = addDays(day, 1);
      totalDays++;
    }

    let holi_day = 0;
    let ttlday_joining = 0;
    if (
      joining_date.getMonth() === monthStart.getMonth() &&
      joining_date.getFullYear() === monthStart.getFullYear()
    ) {
      while (joining_date <= monthEnd) {
        var holi_date = format(joining_date, "yyyy-MM-dd");
        if (holidayDates.includes(holi_date)) {
          holi_day++;
        }
        joining_date = addDays(joining_date, 1);
        ttlday_joining++;
      }

      holi = holi_day;
      totalDays = ttlday_joining;
    }

    var totalHours = totalDays * 8;
    var holidayHours = holi * 8;
    this.setState({
      //            applicable: totalHours,
      applicable: totalHours - holidayHours,
    });

    return days;
  }

  // get all styled cells with right click functionality for a project-activity combination
  getDays(
    data,
    pro_id,
    act_id,
    currDate,
    holidayDates,
    numWeeks,
    currMonthAllowDay,
    prevMonthAllowedEdits,
    isRemoved,
    pendingDates
  ) {
    var hours = {},
      days = [],
      rejectDates = this.state.rejectedDates;
    var dateHours = this.state.totalDateHours,
      vals = this.state.allDatesValues;
    var descriptions = this.state.allDatesDescriptions,
      queries = this.state.allDatesClientQuery;
    // var statuses = this.state.allStatus
    for (let i = 0; i < data.length; i++) {
      hours[data[i].date] = [
        parseFloat(data[i].hour),
        data[i].status,
        data[i].id,
        data[i].description,
        data[i].is_client_query,
      ];
    }

    const monthStart = startOfMonth(currDate);
    const monthEnd = endOfMonth(monthStart);
    var today = new Date();

    if (today > monthEnd) {
      today = monthEnd;
    }

    var currDateObj = new Date();
    var d = currDateObj.getDay();
    var diff = currDateObj.getDate() - d + (d == 0 ? -6 : 1);
    var lastMonday = new Date(currDateObj.setDate(diff));

    var lastUnfreezeDay = lastMonday.getDate() - numWeeks * 7;
    lastMonday.setDate(lastUnfreezeDay);

    var formatLastMonday = format(lastMonday, "yyyy-MM-dd");
    var formatToday = format(new Date(), "yyyy-MM-dd");
    
    var todaysDay = format(new Date(), "d");
    var currMonth = format(new Date(), "M");
    if (currMonth != format(new Date(currDate), "M")) {
      var firstOfThisMonth = new Date();
      firstOfThisMonth.setDate(1);
      if (todaysDay > currMonthAllowDay) {
        formatLastMonday = format(firstOfThisMonth, "yyyy-MM-dd");
        // chnage first day of week for QIC team
        if (this.state.country_code === 4) {
          var QIC_formatLastMonday = new Date(formatLastMonday);
          formatLastMonday = format(
            QIC_formatLastMonday.setDate(QIC_formatLastMonday.getDate() + 1),
            "yyyy-MM-dd"
          );
        }
        
      } else {
      
        var prevMonthLastMonday = startOfISOWeek(monthEnd);

        lastUnfreezeDay =
          prevMonthLastMonday.getDate() - (prevMonthAllowedEdits - 1) * 7;
        prevMonthLastMonday.setDate(lastUnfreezeDay);
        formatLastMonday = format(prevMonthLastMonday, "yyyy-MM-dd");
        // console.log("formatelastmonday", formatLastMonday);
      }
    }

    // var latestDate = new Date()
    // latestDate.setFullYear(2020)
    // latestDate.setMonth(1)

    let day = monthStart;

    var val = "",
      id,
      color = "",
      status = 0,
      clr = "white",
      disable = true;

    // starting day sunday for QIC team
    if (this.state.country_code === 4) {
      var QIC_formatLastMonday = new Date(formatLastMonday);
      formatLastMonday = format(
        QIC_formatLastMonday.setDate(QIC_formatLastMonday.getDate() - 1),
        "yyyy-MM-dd"
      );
    }

    this.setState({
      client_query_disabled_dates: {
        formatLastMonday: formatLastMonday,
        formatToday: formatToday,
      },
    });
    while (day <= today) {
      var currDay = format(day, "yyyy-MM-dd");
      if (!dateHours.hasOwnProperty(currDay)) {
        dateHours[currDay] = 0;
      }
      if (this.state.empSelected.pending.includes(day.getDate())) {
        disable = false

      }
      else if (this.state.empSelected.rejected.includes(day.getDate())){
        disable = false
        clr = "#F4B084"
      } else { 
        clr = "#ededed"
        disable = true;
      }
      // if (currDay >= formatLastMonday && currDay <= formatToday) {
      //   // console.log("formatLastMonday", formatLastMonday);
      //   // console.log("currDay", currDay);  
      //   // console.log("formatToday", formatToday);
      //   disable = false;
      // }
      id = `${currDay}/p${pro_id}/a${act_id}`;

      descriptions[id] = "";
      queries[id] = false;
      if (hours.hasOwnProperty(currDay)) {
        val = hours[currDay][0];
        id = hours[currDay][2];
        status = hours[currDay][1];
        if (status === -1) {
          color = "red";
          disable = false;
          rejectDates[currDay] = 1;
        } else if (status === 1) {
          color = amber[400]
          disable= true
        }
        dateHours[currDay] += val;
        descriptions[id] = hours[currDay][3];
        queries[id] = hours[currDay][4];
      }

      if (holidayDates.includes(currDay)) clr = "#ededed";
      if (isSameDay(day, new Date())) clr = "#4DBDC1";
      //else if (format(day, "EEEEE") === "S") clr = "#ededed";
      if (isRemoved == true) {
        // Removed Invoices/Activities /////
        days.push(
          <TableCell key={id} style={{ background: '#ededed' }}>
            <CustomTextField
              type="Number"
              style={{ color: "grey", width: "5ch", background: color }}
              InputProps={{ classes: { input: this.props.classes["input"] } }}
              inputProps={{
                "data-date": currDay,
                "data-status": status,
                min: 0,
                max: 24,
              }}
              autoComplete="off"
              onWheel={(event) => event.currentTarget.blur()}
              //onContextMenu={this.showDialog}
              disabled={true}
              name={id}
              id={id}
              //style={{ width: "5ch", background: color }}
              key={id}
              defaultValue={val}
              //onChange={this.handleHourChange}
            />
          </TableCell>
        );
      }
      else {
        days.push(
          <TableCell key={id} style={{ background: clr }}>
            <CustomTextField
              type="Number"
              style={{ color: "white", width: "5ch", background: color }}
              InputProps={{ classes: { input: this.props.classes["input"] } }}
              inputProps={{
                "data-date": currDay,
                "data-status": status,
                min: 0,
                max: 24,
              }}
              autoComplete="off"
              onWheel={(event) => event.currentTarget.blur()}
              onContextMenu={this.showDialog}
              disabled={disable}
              name={id}
              id={id}
              //style={{ width: "5ch", background: color }}
              key={id}
              defaultValue={val}
              onChange={this.handleHourChange}
            />
          </TableCell>
        );
      }

      vals[id] = val === "" ? 0 : parseFloat(val);
      // statuses[id] = status

      val = "";
      color = "";
      clr = "white";
      status = 0;
      disable = true;
      day = addDays(day, 1);
    }

    this.setState({
      totalDateHours: dateHours,
      // allStatus: statuses,
      allDatesValues: vals,
      prevDateValues: vals,
      allDatesDescriptions: descriptions,
      allDatesClientQuery: queries,
      rejectedDates: rejectDates,
    });

    return days;
  }

  // show description dialog
  showDialog(e) {
    e.preventDefault();

    var name = e.target.name;
    var value = e.target.value;

    // console.log("name: ", name);
    // console.log("value: ", value);

    if (value === "") this.setState({ fillHourAlert: true });
    else {
      var element = document.getElementById(name);
      if (element.disabled === false) {
        var date = element.dataset.date;

        this.setState({
          description: this.state.allDatesDescriptions[name],
          clientQuery: this.state.allDatesClientQuery[name],
          currSelectedCell: name,
          currSelectedValue: value,
          currSelectedDate: format(new Date(date), "dd MMM yyyy"),
          descriptionDialog: true,
        });
      }
    }
  }

  // close description dialog
  closeDialog() {
    this.setState({
      descriptionDialog: false,
      invalidDescLength: false,
    });
  }

  // track changes in state variable of description dialog
  handleDialogChange(e) {
    var name = e.target.name,
      value;
    // validation for description length
    if (e.target.value.length < 256) {
      if (name === "description") value = e.target.value;
      else value = e.target.checked;

      // console.log("name: ", name);
      // console.log("value: ", value);

      this.setState({ [name]: value, invalidDescLength: false });
    } else {
      this.setState({ invalidDescLength: true });
    }
  }

  // store description dialog submit details
  submitDialog() {
    var name = this.state.currSelectedCell;

    // console.log("inside submit description: ", this.state.description);
    // console.log("client query: ", this.state.clientQuery);

    this.setState({
      allDatesMatrix: {
        ...this.state.allDatesMatrix,
        [name]: this.state.currSelectedValue,
      },
      allDatesDescriptions: {
        ...this.state.allDatesDescriptions,
        [name]: this.state.description,
      },
      allDatesClientQuery: {
        ...this.state.allDatesClientQuery,
        [name]: this.state.clientQuery,
      },
      descriptionDialog: false,
    });
  }

  // set totalDateHours empty
  // and toggle the arrow button
  changeState() {
    this.setState({
      totalDateHours: {},
    });
  }

  async componentDidMount(){
    var configData = await this.fetchTimesheetConfigPost();
    this.setState({configData:configData});
  }
  //handle month toggle for project filter dropdown
  async handleMonthToggle() {
    this.setState({isLoading:true, currDate:new Date(localStorage.getItem("date"))})
    console.log(" IN month Toggle")

    fetch(`${process.env.REACT_APP_TIMESHEET_DJANGO_URL}/timesheet/get-hierarchy/${localStorage.getItem('username')}/${localStorage.getItem('date')}/`,
          {
              method: "GET",
              headers: {
                  "Content-Type": "application/json;charset=utf-8",
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
          }
      ).then(async (res) => {
        var data = await res.json();
        this.setState({empList:data.employees, empSelected:null, isLoading:false})
      })

    //////// Commented to Avoid Rerendering ////////
    // var tmsData = await this.fetchTimesheetDataPost()
    // this.setState({ timesheetData: tmsData }, () => { 
    //   //this.setData;
    //   //this.changeState;
    // })
    
  }

  // render timesheet table if timesheet tab selected
  renderTimesheet(joining) {

    var timesheet = (
      <div id="backdated-timehsheet" >
        {/* <BackDatedMonthToggle
          isDisable={new Date(this.state.currDate).getDate() > parseInt(this.state.configData.filter(obj => obj.id == 2)[0]['value'])}
          currDate={this.state.currDate}
          code={this.state.empSelected.employee_code}
          setData={this.setData}
          changeState={this.changeState}
          onMonthToggle={this.handleMonthToggle}
        /> */}
        <BackDatedSingleTable
          ref={this.projectFilterRef}
          onMonthChange={this.monthToggleHandler}
          key={this.state.rejectedDates}
          joiningDate={joining}
          rejectedDates={this.state.rejectedDates}
          daysHeader={this.state.daysHeader}
          rows={this.state.rows}
          totalDateHours={this.state.totalDateHours}
          clientQueryDisabledDates={this.state.client_query_disabled_dates}
          onSubmitClientRes={this.submitClientResHandler}
          employee={this.state.empSelected}
          employeePendingdays={this.state.empSelected.pending}
        />
        <br />
        <br />
        <Footer
          applicable={this.state.applicable}
          clocked={this.state.clocked}
          leaves={this.state.leaves}
          holidays={this.state.holidays}
        />
        <br />
        <br />
        <Button
          onClick={this.handleSubmit}
          variant="contained"
          color="primary"
          style={{ align: "center" }}
        >
          Submit
        </Button>
      </div>
    );

    this.setState({
      currentTable: timesheet,
    });
  }


  async componentDidMount() {

    fetch(`${process.env.REACT_APP_TIMESHEET_DJANGO_URL}/timesheet/get-hierarchy/${localStorage.getItem('username')}/${localStorage.getItem('date')}/`,
          {
              method: "GET",
              headers: {
                  "Content-Type": "application/json;charset=utf-8",
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
          }
      ).then(async (res) => {
        var data = await res.json();
        this.setState({empList:data.employees, isLoading:false})
      })

      var configData = await this.fetchTimesheetConfigPost();
      this.setState({configData:configData});
  }
    
  render() {
      
    const { classes } = this.props;
    const { configData} = this.state;
    var pending = this.state.pending;
    var dateString = "";
    for (let i = 0; i < pending.length; i++) dateString += `${pending[i]}, `;
    if (dateString.length > 0) {
      var len = dateString.length;
      dateString = dateString.slice(0, len - 2);
    }
    if (pending !== undefined) pending = pending.length;
    else pending = "-";

      return (
        <>
          <div className={classes.root}>
          
            <Grid container spacing={3} style={{ marginTop: 5 }}>
                {/* Employee List */}
                <Grid item xs={10} sm={3} >
                  <Autocomplete
                    value={this.state.empSelected}
                    size='small'
                    id="combo-box-demo"
                    options={this.state.empList}
                    getOptionLabel={(option) => String(option.employee_code) +" - "+ String(option.full_name)}    
                    ListboxProps={{ style: { maxHeight: '10rem' } }}
                    noOptionsText ={"No records found."}
                    renderInput={(params) => <TextField {...params} label="Employee" />}
                    onChange={(e,val) => this.onEmployeeChange(e, val)}
                  />
                </Grid>

                {/* Blue Bar */}

               
                {configData.length > 0 && (
                  <div id="backdated-timesheet" style={{
                    marginTop: "20px",
                    width: "100%", // Ensure it stretches across the screen
                    textAlign: "center", // Center align the content
                  }}>
                  <BackDatedMonthToggle
                    isDisable={
                      new Date(this.state.currDate).getDate() >
                      parseInt(
                        this.state.configData.filter((obj) => obj.id == 2)[0]["value"]
                      )
                    }
                    currDate={this.state.currDate}
                    code={
                      this.state.empSelected
                        ? this.state.empSelected.employee_code
                        : null
                    }
                    setData={this.setData}
                    changeState={this.changeState}
                    onMonthToggle={this.handleMonthToggle}
                  />
              </div>
                )}

                {this.state.empSelected &&  <Grid item xs={2} justifyContent="flex-end">
                  <div className={classes.pending}>
                    <Tooltip
                      title={
                        <React.Fragment>
                          <Typography color="inherit">
                            Pending Dates
                          </Typography>
                          {dateString}
                        </React.Fragment>
                      }
                    >
                      <div>Pending Days: {pending}</div>
                    </Tooltip>
                  </div>
                </Grid>}
                {/* Timesheet Table */}
                {this.state.empSelected && <Grid item xs>
                  {this.state.currentTable}
                </Grid>}
          </Grid>

            {/* Changes Recorded */}
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              open={this.state.submitAlert}
              autoHideDuration={5000}
              onClose={this.handleSubmitAlert}
            >
              <Alert onClose={this.handleSubmitAlert} severity="success">
                Changes Recorded
              </Alert>
            </Snackbar>

            {/* 10 Hrs */}
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              open={this.state.totalHourAlert}
              autoHideDuration={5000}
              onClose={this.handleHourAlert}
            >
              <Alert onClose={this.handleHourAlert} severity="warning">
                Total hours should lie between 0-{this.state.configDataByName['Total Hours in Day']}
              </Alert>
            </Snackbar>
            
            {/* Leave Hour */}
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              open={this.state.leaveHourAlert}
              autoHideDuration={5000}
              onClose={this.handleLeaveHourAlert}
            >
              <Alert onClose={this.handleLeaveHourAlert} severity="warning">
                Leave hours should lie between 0-8
              </Alert>
            </Snackbar>
          
            {/* Dialog */}
            {/* <div id="dialog"> */}
              <Dialog
                open={this.state.descriptionDialog}
                onClose={this.closeDialog}
                aria-labelledby="form-dialog-title1"
                fullWidth={true}
                maxWidth="sm"
              >
                <DialogTitle id="form-dialog-title1">
                  {this.state.currSelectedDate}
                </DialogTitle>
                <DialogContent>
                  <TextField
                    autoFocus
                    variant="filled"
                    id="description"
                    name="description"
                    value={this.state.description}
                    onChange={this.handleDialogChange}
                    label="Description"
                    helperText={
                      this.state.invalidDescLength ? (
                        <span style={{ color: "red" }}>
                          Description limited to 255 characters
                        </span>
                      ) : (
                        ""
                      )
                    }
                    fullWidth
                  />
                  <br />
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="clientQuery"
                        id="clientQuery"
                        color="primary"
                        checked={this.state.clientQuery}
                        onChange={this.handleDialogChange}
                      />
                    }
                    label="Was this activity a part of client query?"
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.closeDialog} color="primary">
                    Cancel
                  </Button>
                  <Button
                    disabled={this.state.invalidDescLength}
                    type="submit"
                    onClick={this.submitDialog}
                    color="primary"
                  >
                    Submit
                  </Button>
                </DialogActions>
              </Dialog>

              <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={this.state.fillHourAlert}
                autoHideDuration={5000}
                onClose={this.handleFillHourAlert}
              >
                <Alert onClose={this.handleFillHourAlert} severity="warning">
                  Please fill hours first
                </Alert>
              </Snackbar>
            {/* </div> */}

            {/* No Changes */}
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              open={this.state.noChange}
              autoHideDuration={5000}
              onClose={this.handleNoChange}
            >
              <Alert onClose={this.handleNoChange} severity="info">
                No changes done
              </Alert>
            </Snackbar>

            {/* Client Feedback */}
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              open={this.state.clientValueSubmit}
              autoHideDuration={5000}
              onClose={this.handleFridaySubmitAlert}
            >
              <Alert onClose={this.handleFridaySubmitAlert} severity="success">
                Client value feedback recorded
              </Alert>
            </Snackbar>

            {/*  20 Characters*/}
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              open={this.state.clientValueAlert}
              autoHideDuration={5000}
              onClose={this.handleFridayValueAlert}
            >
              <Alert onClose={this.handleFridayValueAlert} severity="warning">
                Minimum length should be 20 characters
              </Alert>
            </Snackbar>

           {/* On Submit Loading Backdrop */}
            <Backdrop
                className={classes.backdropStyle}
                open={this.state.isLoading}
                >
                <CircularProgress
                    color="primary"
                    style={{
                    marginRight: "50%",
                    marginLeft: "40%",
                    marginBottom: "20%",
                    }}
                />
            </Backdrop>
            
          </div>
          </>
        );
    }
}

export default withStyles(useStyles)(BackdatedTimesheet);