import DateFnsUtils from "@date-io/date-fns";
import MaterialTable from "@material-table/core";
import { ExportCsv } from "@material-table/exporters";
import {
  Button,
  ButtonGroup,
  Grid,
  TextField,
  withStyles,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { format } from "date-fns";
import React from "react";
import "./Timesheet.css";
// import { Doughnut } from 'react-chartjs-2';

const useStyles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
});

class Report extends React.Component {
  constructor(props) {
    super(props);
    var day = parseInt(format(new Date(), "dd"));
    var monthAgo = new Date();
    monthAgo.setDate(0);
    monthAgo.setDate(day);

    this.state = {
      dateFrom: monthAgo,
      dateTo: new Date(),
      title: "",
      columns: [],
      allRows: [],
      currRows: [],
      activeButton: "btn1", // Active Tab Button State
      is_loading: false, // Loading Backdrop State
      pendingActive: true, // State for removing pending days & Download Invoices Dropdown
      invoice_config: [],
      roleWise: {
        project: [],
        activity: [],
        employee: [],
        role: [],
      },
      projectWise: {
        project: [],
        activity: [],
        employee: [],
        role: [],
      },
      activityWise: {
        activity: [],
        project: [],
        employee: [],
        role: [],
      },
      employeeWise: {
        employee: [],
        project: [],
        activity: [],
        role: [],
      },
      allData: {
        employee: [],
        activity: [],
        project: [],
        approved: [],
      },
      pendingDays: {
        employee: [],
        activity: [],
        project: [],
        approved: [],
      },
      dropdowns: [],
      columnDropdown: null,
      currButton: "",
      chart: null,
      employee_code: "",
    };

    this.handleAutoComplete = this.handleAutoComplete.bind(this);
    this.getData = this.getData.bind(this);
    this.getAllData = this.getAllData.bind(this);
    this.handleDateFromChange = this.handleDateFromChange.bind(this);
    this.handleDateToChange = this.handleDateToChange.bind(this);
    this.downloadChart = this.downloadChart.bind(this);
    this.getPendingDays = this.getPendingDays.bind(this);
    this.downloadInvoice = this.downloadInvoice.bind(this);
    this.fetchUser = this.fetchUser.bind(this);
  }
  componentDidMount() {
    this.fetchUser();
    this.getAllData();
  }

  // Start- Active Tab Button functions
  setBtn2() {
    this.setState({
      activeButton: "btn2",
      pendingActive: true,
    });
  }

  setBtn3() {
    this.setState({
      activeButton: "btn3",
      pendingActive: true,
    });
  }

  setBtn4() {
    this.setState({
      activeButton: "btn4",
      pendingActive: true,
    });
  }

  setBtn5() {
    this.setState({
      activeButton: "btn5",
      pendingActive: true,
    });
  }

  setBtn6() {
    this.setState({
      activeButton: "btn6",
      pendingActive: true,
    });
  }
  // End- Active Tab Button functions

  fetchUser() {
    fetch(
      `${process.env.REACT_APP_TIMESHEET_DJANGO_URL}/timesheet/employee-details/`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    ).then(async (res) => {
      var empData = await res.json();
      console.log("employee data: ", empData);
      this.setState({
        employee_code: empData["employee_code"],
      });
    });

    fetch(
      `${process.env.REACT_APP_TIMESHEET_DJANGO_URL}/timesheet/config-value/4/`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    ).then(async (res) => {
      var config_data = await res.json();
      console.log("configdataaa", config_data);
      this.setState({
        invoice_config: config_data[0]["value"].split(","),
      });
    });
  }

  // handle all filtering when values are changed in any filter
  handleAutoComplete(e, listVals, name, button) {
    var values = listVals.map((val) => {
      return val.title;
    });

    console.log("name: ", name);
    console.log("values: ", values);

    var rows = this.state.allRows,
      finalRows = [];
    // For employee-Wise Mapping
    var empCodeMapping = rows.reduce((acc, cur) => ({ ...acc, [cur.employee]: cur?.employee_code }), {})

    var uniqueRoles = [...new Set(rows.map((row) => row["role"]))];
    uniqueRoles.sort();

    if (values.length !== 0) {
      rows = rows.filter(function (row) {
        return values.indexOf(row[name]) > -1;
      });
    }

    // update dropdown options
    var filters = Object.keys(this.state[button]);
    var unique,
      dds = this.state.dropdowns;
    var fieldName = button.split("Wise")[0];
    if (button !== "allData" && fieldName === name) {
      var idx = filters.indexOf(fieldName);
      filters.splice(idx, 1);

      for (let i = 0; i < filters.length; i++) {
        unique = [...new Set(rows.map((row) => row[filters[i]]))];
        unique.sort();
        var options = unique.map((val, idx) => {
          return { id: idx, title: val };
        });
        dds[i]["options"] = options;
        // dds = dds.map((dropdown) =>
        //   dropdown.field === filters[i]
        //     ? { ...dropdown, options } // Update options for other dropdowns
        //     : dropdown
        // );
        
      }
    }

    var keys = Object.keys(this.state[button]);
    idx = keys.indexOf(name);
    keys.splice(idx, 1);
    for (let i = 0; i < keys.length; i++) {
      let vals = this.state[button][keys[i]];
      let key = keys[i];
      if (vals.length !== 0) {
        rows = rows.filter(function (row) {
          return vals.indexOf(row[key]) > -1;
        });
      }
    }

    if (button === "allData") {
      finalRows = rows;
    } else {
      var row;

      // var roles, chart, d, colors = [], labels
      // var r, g, b, color

      unique = [...new Set(rows.map((row) => row[fieldName]))];
      unique.sort();
      // projects = {}
      // for(let j = 0; j < unique.length; j++)
      //     projects[unique[j]] = 0

      // for(let j = 0; j < rows.length; j++)
      //     projects[rows[j][fieldName]] += rows[j]["hour"]

      // for(let j = 0; j < unique.length; j++){
      //     row = {}
      //     row[fieldName] = unique[j]
      //     row["hour"] = projects[unique[j]]
      //     finalRows.push(row)
      // }
      var colData = {};
      for (let j = 0; j < unique.length; j++) {
        let u = unique[j];
        colData[unique[j]] = {};
        // For employee code in employee-wise
        if (button === "employeeWise") { 
          colData[unique[j]]['employee_code'] = empCodeMapping[u]
        }
        // End
        for (let k = 0; k < uniqueRoles.length; k++)
          colData[unique[j]][uniqueRoles[k]] = 0;
        colData[unique[j]]["hour"] = 0;

        var projectRows = rows.filter(function (row) {
          return row[fieldName] === u;
        });

        for (let k = 0; k < projectRows.length; k++) {
          colData[unique[j]][projectRows[k]["role"]] += projectRows[k]["hour"];
          colData[unique[j]]["hour"] += projectRows[k]["hour"];
          colData[unique[j]]["role"] = projectRows[k]["role"];
        }
      }

      for (let j = 0; j < unique.length; j++) {
        row = {};
        row[fieldName] = unique[j];

        keys = Object.keys(colData[unique[j]]);
        for (let k = 0; k < keys.length; k++)
          row[keys[k].toLowerCase()] = colData[unique[j]][keys[k]];

        finalRows.push(row);
      }

      // if only one project is selected
      // show tech/non-tech distribution
      // if((this.state[button][fieldName].length === 1 && name !== fieldName) || (values.length === 1 && name === fieldName)) {
      //     unique = [...new Set(rows.map(row => row["role"]))]
      //     unique.sort()
      //     roles = {}
      //     for(let j = 0; j < unique.length; j++)
      //         roles[unique[j]] = 0

      //     for(let j = 0; j < rows.length; j++)
      //         roles[rows[j]["role"]] += rows[j]["hour"]

      //     console.log(roles)

      //     labels = Object.keys(roles)
      //     for(let i = 0; i < labels.length; i++){
      //         r = Math.floor(Math.random() * 200);
      //         g = Math.floor(Math.random() * 200);
      //         b = Math.floor(Math.random() * 200);
      //         color = 'rgb(' + r + ', ' + g + ', ' + b + ')';

      //         colors.push(color)
      //     }

      //     d = {
      //         labels: labels,
      //         datasets: [{
      //             data: Object.values(roles),
      //             fill: true,
      //             backgroundColor: colors
      //         }]
      //     }

      //     chart = (
      //         <Doughnut
      //             data={d}
      //         />
      //     )
      // } else {
      //     console.log(colData)

      //     labels = Object.keys(colData)
      //     for(let i = 0; i < labels.length; i++){
      //         r = Math.floor(Math.random() * 200);
      //         g = Math.floor(Math.random() * 200);
      //         b = Math.floor(Math.random() * 200);
      //         color = 'rgb(' + r + ', ' + g + ', ' + b + ')';

      //         colors.push(color)
      //     }

      //     d = {
      //         labels: labels,
      //         datasets: [{
      //             data: Object.values(colData),
      //             fill: true,
      //             backgroundColor: colors
      //         }]
      //     }

      //     chart = (
      //         <Doughnut
      //             data={d}
      //         />
      //     )
      // }
    }

    console.log(finalRows);
    this.setState({
      currRows: finalRows,
      [button]: {
        ...this.state[button],
        [name]: values,
      },
      dropdowns: dds.map((dropdown) =>
        dropdown.field === name
          ? {
              ...dropdown,
              value: listVals,
            }
          : dropdown
      ),
      // chart: chart
    });
  }

  // get data for 3 specific tabs(project, activity, employee)
  getData(
    fields,
    headers,
    endpoint,
    title,
    from,
    to,
    button,
    showFields,
    hiddenFields
  ) {
    this.setState({ is_loading: true });
    fetch(
      `${process.env.REACT_APP_TIMESHEET_DJANGO_URL}/report/${endpoint}/?start=${from}&end=${to}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    ).then(async (response) => {
      const data = await response.json();
      var tableData = data;
      var columns = [],
        rows = [];
      var hide = true;
      for (let i = 0; i < fields.length; i++) {
        if (showFields.includes(fields[i])) hide = false;
        var column = {
          field: fields[i],
          title: headers[i],
          hidden: hide,
        };
        hide = true;
        columns.push(column);
      }

      for (let i = 0; i < tableData.length; i++) {
        var row = {};
        for (let j = 0; j < fields.length; j++) {
          row[fields[j]] = tableData[i][j];
        }
        // row["hour"] = Number(Number(row.hour).toFixed(2)); // Restricting value to 2 decimals
        rows.push(row);
      }

      console.log(columns);
      // For employee-Wise Mapping
      var empCodeMapping = rows.reduce((acc, cur) => ({...acc, [cur.employee]:cur?.employee_code}), {})

      let masterField = button.split("Wise")[0];
      var cols = [
        {
          field: masterField,
          title: `${masterField.charAt(0).toUpperCase()}${masterField.slice(
            1
          )}`,
        },
      ];

      hide = false;
      if (button === "employeeWise") hide = true;

      var uniqueRoles = [...new Set(rows.map((row) => row["role"]))];
      uniqueRoles.sort();
      for (let j = 0; j < uniqueRoles.length; j++) {
        cols.push({
          field: uniqueRoles[j].toLowerCase(),
          title: `${uniqueRoles[j]} Efforts`,
          hidden: hide,
        });
      }

      var dropdowns = [],
        finalRows = [],
        unique,
        colDropdown,
        options;
      for (let i = 0; i < fields.length; i++) {
        let field = fields[i];
        if (field === masterField) {
          unique = [...new Set(rows.map((row) => row[masterField]))];
          unique.sort();
          options = unique.map((val, idx) => {
            return { id: idx, title: val };
          });
          colDropdown = (
            <Autocomplete
              multiple
              key={button}
              id={masterField}
              options={options}
              getOptionLabel={(option) => option.title}
              defaultValue={[]}
              onChange={(e, vals) =>
                this.handleAutoComplete(e, vals, masterField, button)
              }
              renderInput={(params) => (
                <TextField {...params} label={masterField.toUpperCase()} />
              )}
            />
          );
          var colData = {};
          for (let j = 0; j < unique.length; j++) {
            let u = unique[j];
            colData[unique[j]] = {};
            // For employee code in employee-wise
            if (button === "employeeWise") { 
              colData[unique[j]]['employee_code'] = empCodeMapping[u]
            }
            // End 
            for (let k = 0; k < uniqueRoles.length; k++)
              colData[unique[j]][uniqueRoles[k]] = 0;
            colData[unique[j]]["hour"] = 0;

            var projectRows = rows.filter(function (row) {
              return row[masterField] === u;
            });

            for (let k = 0; k < projectRows.length; k++) {
              colData[unique[j]][projectRows[k]["role"]] +=
                projectRows[k]["hour"];
              colData[unique[j]]["hour"] += projectRows[k]["hour"];
              colData[unique[j]]["role"] = projectRows[k]["role"];
            }
          }

          for (let j = 0; j < unique.length; j++) {
            row = {};
            row[masterField] = unique[j];

            var keys = Object.keys(colData[unique[j]]);
            for (let k = 0; k < keys.length; k++)
              row[keys[k].toLowerCase()] = colData[unique[j]][keys[k]];

            let temp = 0;
            // Customizing row data for "employee-data" endpoint

            row["hour"] = Number(Number(row.hour).toFixed(2));
            if (row["functional"]) {
              row["functional"] = Number(Number(row.functional).toFixed(2));
            }
            if (row["tech"]) {
              row["tech"] = Number(Number(row.tech).toFixed(2));
            }
            if (endpoint === "employee-data") {
              if (Object.keys(row).length === 5) {
                delete row["tech"];
                delete row["functional"];
                temp = row["hour"];
                delete row["hour"];
                row["hour"] = temp;
              } else {
                delete row["functional"];
                temp = row["hour"];
                delete row["hour"];
                row["hour"] = temp;
              }
            }
            // Customizing row data for "project-data" or "activity-data" endpoint
            else if (
              endpoint === "project-data" ||
              endpoint === "activity-data"
            ) {
              delete row["role"];
            }

            console.log("Employee rows", row);
            finalRows.push(row);
          }
        }
        if (hiddenFields.includes(fields[i])) {
          unique = [...new Set(rows.map((row) => row[fields[i]]))];
          unique.sort();
          options = unique.map((val, idx) => {
            return { id: idx, title: val };
          });
          dropdowns.push({
            key: button,
            field: fields[i],
            options: options,
          });
        }
      }

      if (button === "employeeWise") {
        cols.splice(0,0,{ field: "employee_code", title: "Employee Code" })
        cols.push({ field: "role", title: "Role" });
      }
      cols.push({ field: "hour", title: "Total Efforts" });

      // var colors = []
      // for(let i = 0; i < finalRows.length; i++){
      //     var r = Math.floor(Math.random() * 200);
      //     var g = Math.floor(Math.random() * 200);
      //     var b = Math.floor(Math.random() * 200);
      //     var color = 'rgb(' + r + ', ' + g + ', ' + b + ')';

      //     colors.push(color)
      // }

      // var d = {
      //     labels: Object.keys(colData),
      //     datasets: [{
      //         data: Object.values(colData),
      //         fill: true,
      //         backgroundColor: colors
      //     }]
      // }

      // var chart = (
      //     <Doughnut
      //         data={d}
      //     />
      // )

      this.setState({
        title: title,
        allRows: rows,
        currRows: finalRows,
        columns: cols,
        dropdowns: dropdowns,
        columnDropdown: colDropdown,
        // chart: chart,
        currButton: button,
        is_loading: false,
      });
    });
  }

  // get raw data for all data tab
  getAllData() {
    this.setState({ is_loading: true });
    var fields = [
      "employee_code",
      "employee",
      "activity",
      "project",
      "description",
      "date",
      "hours",
      "approved",
    ];
    var headers = [
      "Employee Code",
      "Employee",
      "Activity",
      "Project",
      "Description",
      "Date",
      "Hours",
      "Approved?",
    ];
    var endpoint = "team-data";
    var title = "All Data";
    var from = format(new Date(this.state.dateFrom), "yyyy-MM-dd");
    var to = format(new Date(this.state.dateTo), "yyyy-MM-dd");

    fetch(
      `${process.env.REACT_APP_TIMESHEET_DJANGO_URL}/report/${endpoint}/?start=${from}&end=${to}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    ).then(async (response) => {
      const data = await response.json();
      // console.log(data)
      var tableData = data;
      // var columns = [],
        var rows = [];
      // for (let i = 0; i < fields.length; i++) {
      //   var column = {
      //     field: fields[i],
      //     title: headers[i],
      //   };
      //   columns.push(column);
      // }

      var columns = fields.map((field, index) => {
        const column = {
          field,
          title: headers[index],
        };
  
        // Add custom sorting for date fields
        if (field.toLowerCase().includes("date")) {
          column.sorting = true;
          column.customSort = (rowA, rowB) => {
            if (!rowA || !rowB) return 0;

            const parseDate = (dateString) => {
              // Convert "26-Dec-2024" to a Date object
              const months = {
                Jan: 0, Feb: 1, Mar: 2, Apr: 3, May: 4, Jun: 5,
                Jul: 6, Aug: 7, Sep: 8, Oct: 9, Nov: 10, Dec: 11,
              };
              const [day, month, year] = dateString.split("-");
              return new Date(year, months[month], parseInt(day));
            };

            const dateA = parseDate(rowA[field]);
            const dateB = parseDate(rowB[field]);

            return dateA - dateB; // Compare dates
          };
        }
  
        return column;
      });

      for (let i = 0; i < tableData.length; i++) {
        var row = {};
        for (let j = 0; j < fields.length; j++) {
          var val = tableData[i][j];
          if (fields[j] === "approved") val = val === -1 ? "N" : "Y";
          row[fields[j]] = val;
        }
        row["hours"] = Number(Number(row.hours).toFixed(2)); // Restricting value to 2 decimals
        rows.push(row);
      }
      // console.log("All data", rows);
      console.log(columns);

      var dropdowns = [],
        unique;
      for (let i = 0; i < fields.length; i++) {
        let field = fields[i];
        if (
          field === "project" ||
          field === "activity" ||
          field === "employee" ||
          field === "approved"
        ) {
          unique = [...new Set(rows.map((row) => row[field]))];
          unique.sort();
          var options = unique.map((val, idx) => {
            return { id: idx, title: val };
          });
          dropdowns.push({
            key: "allData",
            field: fields[i],
            options: options,
          });
        }
      }

      this.setState({
        title: title,
        allRows: rows,
        currRows: rows,
        columns: columns,
        dropdowns: dropdowns,
        currButton: "allData",
        activeButton: "btn1",
        is_loading: false,
        pendingActive: true,
      });
    });
  }

  // Pending Days Function
  getPendingDays() {
    this.setBtn5();
    this.setState({ is_loading: true });
    var fields = [
      "employee_code_id",
      "employee_code__full_name",
      "missing_days",
      "employee_code__stl_employee_code",
      "employee_code__stl_employee_code__stl_name",
      "employee_code__reporting_manager_employee_code",
      "employee_code__reporting_manager_employee_code__first_name",
    ];
    var headers = [
      "Employee ID",
      "Employee",
      "Missing Days",
      "STL Employee Code",
      "STL Name",
      "Reporting Manager Employee Code",
      "Reporting Manager Name",
    ];
    var endpoint = "pending-days";
    var title = "Current Month Pending Days";
    // console.log(this.state.dateFrom, this.state.dateTo);
    // var from = format(new Date(this.state.dateFrom), "yyyy-MM-dd");
    // var to = format(new Date(this.state.dateTo), "yyyy-MM-dd");

    fetch(`${process.env.REACT_APP_TIMESHEET_DJANGO_URL}/report/${endpoint}/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }).then(async (response) => {
      const data = await response.json();
      // console.log(data)
      var tableData = data;
      var columns = [],
        rows = [];
      for (let i = 0; i < fields.length; i++) {
        var column = {
          field: fields[i],
          title: headers[i],
        };
        columns.push(column);
      }

      for (let i = 0; i < tableData.length; i++) {
        var row = {};
        for (let j = 0; j < fields.length; j++) {
          var val = tableData[i][j];

          row[fields[j]] = val;
        }
        // row["hours"] = Number(Number(row.hours).toFixed(2));
        rows.push(row);
      }
      console.log(columns);

      this.setState({
        title: title,
        allRows: rows,
        currRows: rows,
        columns: columns,
        currButton: "pendingDays",
        activeButton: "btn5",
        is_loading: false,
        pendingActive: false,
      });
    });
  }

  downloadInvoice() {
    this.setBtn6();
    this.setState({ is_loading: true });
    var fields = [
      "invoice_head_name",
      "display_name",
      "client__client_name",
      "billing_entity__billing_entity_name",
      "solution__solution_name",
      "engagement_type__engagement_type_name",
      "invoice_is_poc",
    ];
    var headers = [
      "Invoice Head Name",
      "Invoice Display Name",
      "Client Name",
      "Billing Entity Name",
      "Solution Name",
      "Engagement Type Name",
      "Invoice Is POC",
    ];
    var endpoint = "download-invoice";
    var title = "Download Invoice";
    // console.log(this.state.dateFrom, this.state.dateTo);
    // var from = format(new Date(this.state.dateFrom), "yyyy-MM-dd");
    // var to = format(new Date(this.state.dateTo), "yyyy-MM-dd");

    fetch(
      `${process.env.REACT_APP_TIMESHEET_DJANGO_URL}/timesheet/${endpoint}/`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    ).then(async (response) => {
      const data = await response.json();
      console.log(data);
      var tableData = data;
      var columns = [],
        rows = [];
      for (let i = 0; i < fields.length; i++) {
        var column = {
          field: fields[i],
          title: headers[i],
        };
        if (column.title !== "ID") {
          columns.push(column);
        }
      }

      for (let i = 0; i < tableData.length; i++) {
        var row = {};
        for (let j = 0; j < fields.length; j++) {
          var val = tableData[i][j];
          if (fields[j] === "approved") val = val === -1 ? "N" : "Y";
          row[fields[j]] = val;
        }

        // row["hours"] = Number(Number(row.hours).toFixed(2));
        rows.push(row);
      }
      console.log(columns);
      console.log(this.state.employee_code);
      this.setState({
        title: title,
        allRows: rows,
        currRows: rows,
        columns: columns,
        currButton: "downloadInvoice",
        activeButton: "btn6",
        is_loading: false,
        pendingActive: false,
      });
    });
  }

  handleDateFromChange(e, value) {
    console.log("value: ", value);
    var date = value.split("/");
    var d = `${date[1]}/${date[0]}/${date[2]}`;
    console.log(d);


    this.setState(
      {
        dateFrom: new Date(d),
        dropdowns: this.state.dropdowns.map(dropdown => ({
          ...dropdown,
          value: [], // Reset selected values
        })),
      }, function () {
      if (this.state.currButton !== "") {
        console.log("clicked: ", this.state.currButton);
        document.getElementById(this.state.currButton).click();
      }
    });
  }

  handleDateToChange(e, value) {
    console.log("value: ", value);
    var date = value.split("/");
    var d = `${date[1]}/${date[0]}/${date[2]}`;
    console.log(d);

    this.setState({ dateTo: new Date(d),
      dropdowns: this.state.dropdowns.map(dropdown => ({
        ...dropdown,
        value: [], // Reset selected values
      })),
     }, function () {
      if (this.state.currButton !== "") {
        console.log("clicked: ", this.state.currButton);
        document.getElementById(this.state.currButton).click();
      }
    });
  }

  // download chart in .png format
  downloadChart(e) {
    let chart = document.getElementsByTagName("canvas")[0];

    const ctx = chart.getContext("2d");
    ctx.globalCompositeOperation = "destination-over";
    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, chart.width, chart.height);

    var href = chart.toDataURL("image/png");

    var link = document.createElement("a");
    link.download = `${this.state.title}.png`;
    link.href = href;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  render() {
    // check for sso cookie, if not present redirect to login
    var jwt = document.cookie
      .split("; ")
      .find((row) => row.startsWith("C-X-ssoJwt="));
    if (jwt === undefined) {
      window.location = process.env.REACT_APP_SSO_LOGIN_URL;
    }

    const { classes } = this.props;

    return (
      <div ref={this.setRef}>
        {this.state.is_loading ? (
          <div className="backdrop">
            <div className="loader"></div>
          </div>
        ) : null}
        {this.state.activeButton !== "btn5" ? <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container justifyContent="space-evenly">
            <KeyboardDatePicker
              inputVariant="filled"
              margin="normal"
              name="dateFrom"
              id="date-picker-from"
              label="From"
              autoOk={true} // Start-Customized OK Cancel Labels
              okLabel={""}
              cancelLabel={""} //End-Customized OK Cancel Labels
              format="dd/MM/yyyy"
              value={this.state.dateFrom}
              onChange={this.handleDateFromChange}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
            <KeyboardDatePicker
              inputVariant="filled"
              margin="normal"
              name="dateTo"
              id="date-picker-to"
              label="To"
              autoOk={true} // Start-Customized OK Cancel Labels
              okLabel={""}
              cancelLabel={""} //End-Customized OK Cancel Labels
              format="dd/MM/yyyy"
              value={this.state.dateTo}
              onChange={this.handleDateToChange}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </Grid>
        </MuiPickersUtilsProvider> : <h3 style={{marginTop:10}}>Current Month Pending Days</h3>}
        <br />
        <div className={classes.root}>
          <ButtonGroup
            color="primary"
            aria-label="small outlined button group"
            size="large"
          >
            <Button
              variant={
                this.state.activeButton === "btn1" ? "contained" : "outlined"
              }
              id="allData"
              onClick={this.getAllData}
            >
              All Data
            </Button>
            <Button
              variant={
                this.state.activeButton === "btn2" ? "contained" : "outlined"
              }
              id="projectWise"
              onClick={() => {
                var fields = [
                  "project",
                  "activity",
                  "employee",
                  "role",
                  "hour",
                ];
                var headers = [
                  "Project",
                  "Activity",
                  "Employee",
                  "Role",
                  "Hours",
                ];
                var endpoint = "project-data";
                var title = "Project-Wise Data";
                var from = format(new Date(this.state.dateFrom), "yyyy-MM-dd");
                var to = format(new Date(this.state.dateTo), "yyyy-MM-dd");
                var button = "projectWise";
                var showFields = ["project", "hour"];
                var hiddenFields = ["activity", "employee", "role"];
                this.setBtn2();
                this.getData(
                  fields,
                  headers,
                  endpoint,
                  title,
                  from,
                  to,
                  button,
                  showFields,
                  hiddenFields
                );
              }}
            >
              Project-Wise Data
            </Button>
            <Button
              variant={
                this.state.activeButton === "btn3" ? "contained" : "outlined"
              }
              id="activityWise"
              onClick={() => {
                var fields = [
                  "activity",
                  "project",
                  "employee",
                  "role",
                  "hour",
                ];
                var headers = [
                  "Activity",
                  "Project",
                  "Employee",
                  "Role",
                  "Hours",
                ];
                var endpoint = "activity-data";
                var title = "Activity-Wise Data";
                var from = format(new Date(this.state.dateFrom), "yyyy-MM-dd");
                var to = format(new Date(this.state.dateTo), "yyyy-MM-dd");
                var button = "activityWise";
                var showFields = ["activity", "hour"];
                var hiddenFields = ["project", "employee", "role"];
                this.setBtn3();
                this.getData(
                  fields,
                  headers,
                  endpoint,
                  title,
                  from,
                  to,
                  button,
                  showFields,
                  hiddenFields
                );
              }}
            >
              Activity-Wise Data
            </Button>
            {/* <Button
                            id="roleWise"
                            onClick={() => {
                                var fields = ['project', 'activity', 'employee', 'role', 'hour']
                                var headers = ['Project', 'Activity', 'Employee', 'Role', 'Hours']
                                var endpoint = "role-data"
                                var title = "Role-Wise Data"
                                var from = format(new Date(this.state.dateFrom), "yyyy-MM-dd")
                                var to = format(new Date(this.state.dateTo), "yyyy-MM-dd")
                                var button = "roleWise"
                                var showFields = ['role', 'hour']
                                var hiddenFields = ['project', 'activity', 'employee']

                                this.getData(fields, headers, endpoint, title, from, to, button, showFields, hiddenFields)
                            }}
                        >
                            Role-Wise Data
                        </Button> */}
            <Button
              id="employeeWise"
              variant={
                this.state.activeButton === "btn4" ? "contained" : "outlined"
              }
              onClick={() => {
                var fields = [
                  "employee_code",
                  "employee",
                  "project",
                  "activity",
                  "role",
                  "hour",
                ];
                var headers = [
                  "Employee Code",
                  "Employee",
                  "Project",
                  "Activity",
                  "Role",
                  "Hours",
                ];
                var endpoint = "employee-data";
                var title = "Employee-Wise Data";
                var from = format(new Date(this.state.dateFrom), "yyyy-MM-dd");
                var to = format(new Date(this.state.dateTo), "yyyy-MM-dd");
                var button = "employeeWise";
                var showFields = ["employee_code","employee", "hour"];
                var hiddenFields = ["project", "activity", "role"];
                this.setBtn4();
                this.getData(
                  fields,
                  headers,
                  endpoint,
                  title,
                  from,
                  to,
                  button,
                  showFields,
                  hiddenFields
                );
              }}
            >
              Employee-Wise Data
            </Button>
            <Button
              variant={
                this.state.activeButton === "btn5" ? "contained" : "outlined"
              }
              id="pendingDays"
              onClick={this.getPendingDays}
            >
              Pending Days
            </Button>
            {this.state.invoice_config.includes(this.state.employee_code) ? (
              <Button
                variant={
                  this.state.activeButton === "btn6" ? "contained" : "outlined"
                }
                id="downloadInvoice"
                onClick={this.downloadInvoice}
              >
                Download Invoices
              </Button>
            ) : null}
          </ButtonGroup>
        </div>
        <br />
        <Grid container spacing={3}>
          {
            // !(this.state.currButton === "allData") &&
            // <Grid item xs>
            //     {this.state.columnDropdown}
            // </Grid>
          }
          <Grid item xs>
            <Grid container spacing={3}>
              {!(this.state.currButton === "allData") && (
                <Grid item sm={4} xs={12}>
                  {this.state.pendingActive ? this.state.columnDropdown : null}
                </Grid>
              )}
              {this.state.pendingActive
                ? this.state.dropdowns.map((dropdown) => {
                    return (
                      <Grid item xs>
                        <Autocomplete
                          multiple
                          // style={{ width: 300 }}
                          value={dropdown.value || []} // Controlled component
                          key={dropdown.key}
                          id={dropdown.field}
                          options={dropdown.options}
                          getOptionLabel={(option) => option.title}
                          defaultValue={[]}
                          onChange={(e, vals) =>
                            this.handleAutoComplete(
                              e,
                              vals,
                              dropdown.field,
                              dropdown.key
                            )
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={dropdown.field.toUpperCase()}
                            />
                          )}
                        />
                      </Grid>
                    );
                  })
                : null}
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          {
            // !(this.state.currButton === "allData" || this.state.currButton === "") &&
            // <Grid item xs>
            //     <IconButton onClick={this.downloadChart}>
            //         <GetAppIcon />
            //     </IconButton>
            //     {this.state.chart}
            // </Grid>
          }
          <Grid item xs>
            <MaterialTable
              options={{
                filtering: false,
                pageSize: 10,
                sorting: true,
                emptyRowsWhenPaging: false,
                pageSizeOptions: [10, 50, 100, 250],
                headerStyle: {
                  backgroundColor: "#2474FC",
                  color: "#FFF",
                },
                rowStyle: {
                  backgroundColor: "#ededed",
                },
                exportMenu: [
                  {
                    label: "Export CSV",
                    exportFunc: (cols, datas) => {
                      ExportCsv(cols, this.state.currRows, this.state.title);
                    },
                  },
                ],
              }}
              title={this.state.title}
              columns={this.state.columns}
              data={this.state.currRows}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(useStyles)(Report);
