import React from "react";

import { withStyles } from "@material-ui/core/styles";
import { Button, makeStyles } from "@material-ui/core";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import Typography from "@mui/material/Typography";

import "./ClickupData.css";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
  buttonContainer: {
    display: 'flex', 
    gap: '10px', 
    padding: '20px', 
    justifyContent: 'center', 
    width: '100%', 
  },
  uploadButton: {
    backgroundColor: "#2474FC",
    color: "#fff",
    "&:hover": {
      background: "#4DBDC1",
    },
  },
  logoutButton: {
    backgroundColor: theme.palette.error.main,
    color: "#fff",
    "&:hover": {
      backgroundColor: theme.palette.error.dark,
    },
  },
  primaryButton: {
    backgroundColor: "#4DBDC1",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#2474FC",
    },
  },
}));

const CentralizedTicketing = () => {
  const customerTicketUrl = process.env.REACT_APP_CUSTOMER_TICKET_URL;
  const supportTicketUrl = process.env.REACT_APP_TICKET_RAISE_URL;
  const creativeTicketUrl = process.env.REACT_APP_CREATIVE_TICKET_URL;
  const handleRedirect = (url) => {
    window.location.href = url;
  };

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography
        variant="h2"
        sx={{
          fontFamily: "calibri",
          fontSize: "2rem",
          fontWeight: "bold",
          color: "black",
          textAlign: "center",
          marginTop: "2px",
          margin: "8px 0",
          marginBottom: "2px",
          padding: "5px",
        }}
      >
        Centralized Ticketing System
      </Typography>
      <div className={classes.buttonContainer}>
        <Button
          variant="contained"
          startIcon={<HelpOutlineIcon />}
          className={classes.primaryButton}
          onClick={() => handleRedirect(supportTicketUrl)}
        >
          IT Infra Support
        </Button>

        <Button
          variant="contained"
          startIcon={<HelpOutlineIcon />}
          className={classes.primaryButton}
          onClick={() => handleRedirect(customerTicketUrl)}
        >
          Customer Value Impact
        </Button>

        <Button
          variant="contained"
          startIcon={<HelpOutlineIcon />}
          className={classes.primaryButton}
          onClick={() => handleRedirect(creativeTicketUrl)}
        >
          Creative
        </Button>
      </div>
    </div>
  );
};

export default withStyles()(CentralizedTicketing);