/* eslint-disable */
import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { format, addDays, startOfMonth, endOfMonth, isSameDay } from "date-fns";
import {
  TextField,
  withStyles,
  Select,
  MenuItem,
  Button,
  LinearProgress,
  Box,
  Typography,
  Snackbar,
  Grid,
  InputLabel,
  FormControl,
} from "@material-ui/core";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import amber from "@material-ui/core/colors/amber";
import MonthToggle from "./MonthToggle";
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import SimpleMonthToggle from "./SimpleMonthToggle";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#1a90ff",
  },
}))(LinearProgress);

const styles = (theme) => ({
  input: {
    textAlign: "center",
    "&:disabled": {
      color: "#212121",
    },
  },
  backdropStyle: {
    zIndex: theme.zIndex.drawer + 1,
    color: "white",
    height: "120%",
    width: "120%",
    //position: "relative",
  },
});

const StickyTableCell = withStyles((theme) => ({
  head: {
    left: 0,
    position: "sticky",
    zIndex: theme.zIndex.appBar + 2,
  },
  body: {
    backgroundColor: "white",
    left: 0,
    position: "sticky",
    zIndex: theme.zIndex.appBar + 1,
  },
}))(TableCell);

const MuiTableCell = withStyles({
  root: {
    borderBottom: "none",
  },
})(TableCell);

class Team1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      projects: [],
      allTypes: [],
      currProject: "-",
      is_tech: true,
      currType: "0",
      peoples: [],
      curUserDetails: {},
      noRecord: false,
      exportErr:false,

      currDate: new Date(localStorage.getItem("date")),
      daysHeader: [],
      allDatesMatrix: {},
      allDatesValues: {},
      rejectedObjects: [],
      rows: [],
      currEmp: "-",
      progress: 0,
      noChange: false,
      submitAlert: false,
      funcNonTech: false,
      openDialog: false,
      is_loading:false,
    };

    this.fetchProjects = this.fetchProjects.bind(this);
    this.fetchTypes = this.fetchTypes.bind(this);
    this.fetchPeoples = this.fetchPeoples.bind(this);
    this.projectChange = this.projectChange.bind(this);
    this.showCurrProject = this.showCurrProject.bind(this);
    this.typeChange = this.typeChange.bind(this);
    this.handleNoRecord = this.handleNoRecord.bind(this);

    this.fetchUniqueCombsUser = this.fetchUniqueCombsUser.bind(this);
    this.setData = this.setData.bind(this);
    this.fetchInvoiceDetails = this.fetchInvoiceDetails.bind(this);
    this.fetchActivityDetails = this.fetchActivityDetails.bind(this);
    this.fetchCombinationDays = this.fetchCombinationDays.bind(this);
    this.updateStatus = this.updateStatus.bind(this);
    this.sendMail = this.sendMail.bind(this);
    this.handleHourClick = this.handleHourClick.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleReasonSubmit = this.handleReasonSubmit.bind(this);
    this.clearSelection = this.clearSelection.bind(this);
    this.handleNoChange = this.handleNoChange.bind(this);
    this.handleFuncNonTech = this.handleFuncNonTech.bind(this);
    this.handleSubmitAlert = this.handleSubmitAlert.bind(this);
    this.getDaysHeader = this.getDaysHeader.bind(this);
    this.getDays = this.getDays.bind(this);
    this.changeState = this.changeState.bind(this);
    this.dropdownChange = this.dropdownChange.bind(this);
    this.onDownloadClick = this.onDownloadClick.bind(this)
    this.handleExportErr = this.handleExportErr.bind(this)
  }

  async componentDidMount() {
    this.setState({
      curUserDetails: await this.getEmployeeDetails(),
    });
    console.log("getdetail", this.state.curUserDetails);
    console.log("func: ", this.props.isFunc);
    console.log("tech: ", this.props.isTech);
    console.log("stl: ", this.props.isStl);
    this.fetchProjects();
  }

  // fetch employee details
  async getEmployeeDetails() {
    return fetch(
      `${process.env.REACT_APP_TIMESHEET_DJANGO_URL}/timesheet/employee-details/`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    ).then((res) => res.json());
  }

  // fetch projects for logged in employee
  fetchProjects() {
    fetch(`${process.env.REACT_APP_TIMESHEET_DJANGO_URL}/timesheet/inv-name/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }).then(async (res) => {
      var data = await res.json();
      console.log("projects: ", data);
      this.setState({
        projects: data,
      });
    });
  }

  // fetch all roles
  fetchTypes() {
    fetch(
      `${process.env.REACT_APP_TIMESHEET_DJANGO_URL}/timesheet/role-value/`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    ).then(async (res) => {
      var data = await res.json();
      console.log(data);
      this.setState({
        allTypes: data,
      });
    });
  }

  // fetch employees for a specific invoice and role selection
  fetchPeoples(pro_id, pro_type, type) {
    // use specific url to fetch peoples
    // for DPA Internal Investments
    var url;
    // if (pro_id === "83")
    //   url = `${process.env.REACT_APP_TIMESHEET_DJANGO_URL}/timesheet/stl-name-user/${type}/`;
    // else
    url = `${process.env.REACT_APP_TIMESHEET_DJANGO_URL}/timesheet/inv-name-user/${pro_id}/${pro_type}/${type}/`;

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }).then(async (res) => {
      var data = await res.json();

      // for (let i = 0; i < data.length; i++) {
      //   if (data[i][1] === this.state.curUserDetails.employee_code) {
      //     data.splice(i, 1);
      //   }
      // }

      this.setState({
        peoples: data,
        noRecord: data.length === 0 ? true : false,
      });
    });
  }

  // handle option change in project dropdown
  projectChange(e) {
    var name = e.target.name;
    var value = e.target.value;

    console.log("name: ", name);
    console.log("value: ", value);

    this.setState(
      {
        currProject: value,
        currType: "0",
        peoples: [],
        currEmp: "-",
      },
      function () {
        if (value !== "-") this.showCurrProject(value);
        else
          this.setState({
            is_tech: true,
            rows: [],
          });
      }
    );
  }

  // fetch types, peoples for selected project
  showCurrProject(value) {
    var type = value.split("/")[1];
    var pro_id = value.split("/")[0];

    if (type === "is_tech") {
      this.setState({
        is_tech: true,
      });
      this.fetchPeoples(pro_id, type, 1);
    } else {
      this.setState({
        is_tech: false,
      });
      this.fetchTypes();
      if (type === "is_functional") {
        //this.setState({ currType: "1" });
        this.setState({is_tech:true})
        this.fetchPeoples(pro_id, type, 2);
      } else this.fetchPeoples(pro_id, type, 0);
    }
  }

  // fetch peoples on option change in role dropdown
  typeChange(e) {
    var name = e.target.name;
    var value = e.target.value;

    console.log("name: ", name);
    console.log("value: ", value);

    this.setState(
      {
        currType: value,
      },
      function () {
        var type = this.state.currProject.split("/")[1];
        var pro_id = this.state.currProject.split("/")[0];

        this.fetchPeoples(pro_id, type, value);
      }
    );
  }

  handleNoRecord(event, reason) {
    if (reason === "clickaway") return;
    this.setState({ noRecord: false });
  }

  isPreviousMonth(date) {
    const currentDate = new Date();
    const cellDate = new Date(date);
    return cellDate.getFullYear() < currentDate.getFullYear() || (cellDate.getFullYear() === currentDate.getFullYear() && cellDate.getMonth() < currentDate.getMonth());
  }

  // fetch selected employee project-activity data
  fetchUniqueCombsUser(date, code) {
    this.setState(
      {
        currDate: date,
        progress: 0,
      },
      function () {
        if (this.state.currEmp === "-") return;

        var month = format(date, "M");
        var year = format(date, "yyyy");
        var pro_id;

        if (this.state.currProject === "83") pro_id = this.state.currProject;
        else pro_id = this.state.currProject.split("/")[0];

        fetch(
          `${process.env.REACT_APP_TIMESHEET_DJANGO_URL}/timesheet/timesheet-list-combi-user/${month}/${year}/${pro_id}/${code}/`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json;charset=utf-8",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        ).then(async (res) => {
          var data = await res.json();
          console.log("unique combinations: ", data);
          await this.setData(date, code, data);
        });
      }
    );
  }

  // fetch all hour data, holidays, etc for selected employee
  async setData(currDate, code, combs) {
    var currMonth = format(currDate, "M");
    var currYear = format(currDate, "yyyy");
    var country = this.props.countryCode;
    var holidayDates = await this.props.fetchHolidays(
      country,
      currMonth,
      currYear
    );

    var daysHeader = this.getDaysHeader(currDate, holidayDates);
    var days = [];

    var hourTotal = combs.length,
      count = 0;
    var currProgress = this.state.progress;

    var row = {},
      rows = [];
    for (let i = 0; i < combs.length; i++) {
      var pro_id = combs[i][0],
        act_id = combs[i][1];
      var id = parseInt(`${pro_id}${act_id}`);

      var invoice = await this.fetchInvoiceDetails(pro_id);
      var pro = invoice["display_name"],
        client = invoice["client_name"],
        bu = invoice["BU"];

      var activity = await this.fetchActivityDetails(act_id);
      var act = activity;

      var hourData = await this.fetchCombinationDays(
        pro_id,
        act_id,
        currMonth,
        code,
        currYear
      );
      console.log("hours data: ", hourData);

      var d = this.getDays(
        hourData,
        pro_id,
        act_id,
        currDate,
        code,
        holidayDates
      );

      var total = 0;
      for (let j = 0; j < hourData.length; j++) {
        total += parseFloat(hourData[j].hour);
      }

      var status = "Approved";
      for (let j = 0; j < hourData.length; j++) {
        if (hourData[j].status === -1) {
          status = "Rejected";
          break;
        }
      }

      days.push(<TableRow key={id}>{d}</TableRow>);

      if (
        pro === "Holiday" ||
        act === "Holiday" ||
        pro === "Leave" ||
        act === "Leave"
      )
        (client = "-"), (bu = "-");
      row = {
        id: id,
        code: code,
        client: client,
        bu: bu,
        pro_id: pro_id,
        project: pro,
        act_id: act_id,
        activity: act,
        total: total,
        approval: status,
        days: d,
      };
      rows.push(row);

      count++;
      currProgress = (count / hourTotal) * 100;
      console.log("current progress: ", currProgress);
      this.setState({ progress: currProgress });
    }

    console.log("code: ", code);
    console.log("rows: ", rows);

    this.setState(
      {
        currDate: currDate,
        daysHeader: daysHeader,
        rows: rows,
        progress: 100,
      },
      function () {
        var element = document.getElementById("todayHeader");
        if (element !== null)
          element.scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "nearest",
          });
      }
    );
  }

  // fetch invoice details for a specific invoice id
  async fetchInvoiceDetails(pro_id) {
    return fetch(
      `${process.env.REACT_APP_TIMESHEET_DJANGO_URL}/timesheet/emp-invoice-name/${pro_id}/`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    ).then((res) => res.json());
  }

  // fetch activity details for a specific activity id
  async fetchActivityDetails(act_id) {
    return fetch(
      `${process.env.REACT_APP_TIMESHEET_DJANGO_URL}/timesheet/emp-activity-name/${act_id}/`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    ).then((res) => res.json());
  }

  // fetch hour data for a specific project-activity combination
  async fetchCombinationDays(pro, act, currMonth, code, currYear) {
    return fetch(
      `${process.env.REACT_APP_TIMESHEET_DJANGO_URL}/timesheet/timesheet-activity-invoice-list-month/${code}/${pro}/${act}/${currMonth}/${currYear}/`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    ).then((res) => res.json());
  }

  // update status of one cell in the database by sending request
  async updateStatus(dateObj) {
    fetch(
      `${process.env.REACT_APP_TIMESHEET_DJANGO_URL}/timesheet/timesheet-reject-hours/${dateObj.id}/`,
      {
        // mode: 'no-cors',
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(dateObj),
      }
    );
  }
  // send request to backend to mail the employee of which records were rejected
  async sendMail(emailData) {
    fetch(`${process.env.REACT_APP_TIMESHEET_DJANGO_URL}/timesheet/email/`, {
      // mode: 'no-cors',
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(emailData),
    });
  }

  // handle color and status changes on cell click
  handleHourClick(e) {
    var name = e.target.name;
    console.log("name: ", name);

    // if functional lead and non-tech peoples
    // show alert: you can't reject

    // Commented as per new requirement
    // if (this.props.isFunc && this.state.currType.toString() === "1") {
    //   console.log("functional lead trying to reject tech people records");
    //   this.setState({ funcNonTech: true });
    //   return;
    // }

    var element = document.getElementById(name);
    var status = parseInt(element.dataset.status);
    var prevStatus = parseInt(element.dataset.prevstatus);
    var value = element.dataset.hour;

    if (value !== "") {
      var color;
      if (status === 0 || status === 1) {
        status = -1;
        color = "red";
      } else if (status === -1) {
        if (prevStatus === -1) {
          status = 0;
          color = "white";
        } else {
          status = prevStatus;
          if (prevStatus === 1) color = amber[400];
          else color = "white";
        }
      }

      this.setState({
        allDatesMatrix: {
          ...this.state.allDatesMatrix,
          [name]: status,
        },
        allDatesValues: {
          ...this.state.allDatesValues,
          [name]: value,
        },
      });

      element.dataset.status = status;
      element.style.background = color;
      console.log("color: ", color);
      console.log("status: ", status);
    } else {
      console.log("empty date clicked");
    }
  }

  // close reason dialog
  closeDialog() {
    this.setState({ openDialog: false });
  }

  // handle all changes and submit to backend
  async handleSubmit(e) {
    e.preventDefault();

    var allDates = this.state.allDatesMatrix;
    var keys = Object.keys(allDates);

    var rejectedObjs = [];
    var dateObj = {},
      changes = false;
    for (let i = 0; i < keys.length; i++) {
      changes = true;
      var status = allDates[keys[i]];
      var value = this.state.allDatesValues[keys[i]];
      var dateObj = {
        id: parseInt(keys[i]),
        hour: value,
        status: status,
        update_date: format(new Date(), "yyyy-MM-dd HH:mm:ss.SSSSSS"),
      };
      console.log(dateObj);
      rejectedObjs.push(dateObj);
      // OLD - updating status of rejected records
      //await this.updateStatus(dateObj);
    }

    // if "changes" is false then no changes were made
    // as no request was done
    if (!changes) this.setState({ noChange: true });
    else {
      // open reason dialog
      this.setState({
        openDialog: true,
        rejectedObjects: rejectedObjs,
      });
    }
  }

  // record the rejection reason and submit to backend
  async handleReasonSubmit(e) {
    e.preventDefault();

    var reason = document.getElementById("comment").value;
    console.log("reason: ", reason);

    var emailData = {
      rejects: this.state.rejectedObjects,
      comment: reason,
    };
    // NEW -- updating status of rejected records
    for (const dateObj of this.state.rejectedObjects) {
      await this.updateStatus(dateObj);
    }
    // send mail after reason submit with all rejections
    await this.sendMail(emailData);
    this.setState({
      submitAlert: true,
      allDatesMatrix: {},
      openDialog: false,
    });
  }

  // clear all selection/undo clicks by the user
  clearSelection() {
    var keys = Object.keys(this.state.allDatesMatrix);
    for (let i = 0; i < keys.length; i++) {
      var name = keys[i];
      var element = document.getElementById(name);
      var status = parseInt(element.dataset.status);
      var prevStatus = parseInt(element.dataset.prevstatus);

      var color;
      status = prevStatus;
      if (status === 0) color = "white";
      else if (status === 1) color = amber[400];
      else color = "red";

      element.dataset.status = status;
      element.style.background = color;
      console.log("color: ", color);
      console.log("status: ", status);
    }
    this.setState({
      allDatesMatrix: {},
    });
  }

  // close no change alert
  handleNoChange(event, reason) {
    if (reason === "clickaway") return;
    this.setState({ noChange: false });
  }

  // close functional lead not allowed to edit tech records alert
  handleFuncNonTech(event, reason) {
    if (reason === "clickaway") return;
    this.setState({ funcNonTech: false });
  }

  // Export Error 
  handleExportErr(event, reason) {
    if (reason === "clickaway") return;
    this.setState({ exportErr: false });
  }

  // close submit alert
  handleSubmitAlert(event, reason) {
    if (reason === "clickaway") return;
    this.setState({ submitAlert: false });
  }

  // get days headers with colors on holidays/current day
  getDaysHeader(currDate, holidayDates) {
    const monthStart = startOfMonth(currDate);
    const monthEnd = endOfMonth(monthStart);

    let days = [];
    let day = monthStart;

    var color = "white";
    while (day <= monthEnd) {
      const cloneDay = format(day, "d");
      const weekDay = format(day, "EEE");
      var date = format(day, "yyyy-MM-dd");

      if (holidayDates.includes(date)) {
        color = "#ededed";
      }

      if (isSameDay(day, new Date())) {
        color = "#4DBDC1";
      } else if (format(day, "EEEEE") === "S") {
        color = "#ededed";
      }

      if (isSameDay(day, new Date())) {
        days.push(
          <TableCell
            id="todayHeader"
            align="center"
            style={{ background: color }}
          >
            {cloneDay}
            <br />({weekDay})
          </TableCell>
        );
      } else {
        days.push(
          <TableCell align="center" style={{ background: color }}>
            {cloneDay}
            <br />({weekDay})
          </TableCell>
        );
      }

      color = "white";
      day = addDays(day, 1);
    }

    return days;
  }

  // get cells for a project-activity combination
  getDays(data, pro_id, act_id, currDate, code, holidayDates) {
    var hours = {},
      days = [];
    for (let i = 0; i < data.length; i++) {
      hours[data[i].date] = [
        parseFloat(data[i].hour),
        data[i].status,
        data[i].id,
      ];
    }

    const monthStart = startOfMonth(currDate);
    const monthEnd = endOfMonth(monthStart);
    var today = new Date();

    if (today > monthEnd) {
      today = monthEnd;
    }

    let day = monthStart;

    var val = "",
      id,
      color = "",
      status = "",
      clr = "white";
    while (day <= today) {
      var currDay = format(day, "yyyy-MM-dd");

      id = `${currDay}/p${pro_id}/a${act_id}/${code}`;
      if (hours.hasOwnProperty(currDay)) {
        val = hours[currDay][0];
        id = hours[currDay][2];
        status = hours[currDay][1];
        if (status === -1) {
          color = "red";
        } else if (status === 1) {
          color = amber[400];
        }
      }

      if (holidayDates.includes(currDay)) clr = "#ededed";
      if (isPreviousMonth(day)) {
        clr = "#ededed"; // Set background color for previous month cells
      }
      if (isSameDay(new Date(), day)) clr = "#4DBDC1";
      else if (format(day, "EEEEE") === "S") clr = "#ededed";

      days.push(
        <TableCell style={{ background: clr }}>
          <TextField
            InputProps={{
              classes: { input: this.props.classes["input"], readOnly: true },
            }}
            inputProps={{
              "data-status": status,
              "data-hour": val,
              "data-rowid": id,
              "data-empcode": code,
              "data-prevstatus": status,
            }}
            autoComplete="off"
            name={id}
            id={id}
            style={{ width: "5ch", background: color }}
            key={val}
            value={val}
            onClick={this.handleHourClick} 
            disabled={isPreviousMonth(day)} // Disable the TextField for previous month cells
          />
        </TableCell>
      );

      val = "";
      color = "";
      clr = "white";
      status = "";
      day = addDays(day, 1);
    }

    return days;
  }

  // set totalDateHours empty
  // and toggle the arrow button
  changeState() {
    this.setState({
      rows: [],
      currDate: new Date(localStorage.getItem("date"))
    });
  }

  // handle option change in employee dropdown
  dropdownChange(e) {
    var name = e.target.name;
    var value = e.target.value;

    // console.log("name: ", name);
    // console.log("value: ", value);

    var date = localStorage.getItem("date");

    this.setState(
      {
        currEmp: value,
        rows: [],
      },
      this.fetchUniqueCombsUser(new Date(date), value)
    );
  }

  getCSVString = (arr) => {
    if (arr.length == 0) {
      return "";
    }
  
    const csvArray = [Object.keys(arr[0])].concat(arr);
    return csvArray
      .map((row) => {
        return Object.values(row);
      })
      .join("\n");
  };
 
  getCSVBlob = (csvString) => {
    return new Blob([csvString], { type: "text/csv" });
  };
 
  serveCSVBlob = (csvBlob) => {
    const url = URL.createObjectURL(csvBlob);
 
    const link = document.createElement("a");
    link.href = url;
    let fileName = `${format(new Date(localStorage.getItem('date')), "MMM yyyy")}.csv`
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    URL.revokeObjectURL(url);
  };
  
  // on download click 
  onDownloadClick() { 
    this.setState({is_loading:true})
    let invoice_data=[]
    let is_all=false
    if (this.state.currProject==="-"){
      is_all=true;
      {this.state.projects.map((project) => {
        var role = "";
        if (project["flag"]) {
          var r = project["role"].split("_")[1];
          role = ` (${r[0].toUpperCase()}${r.slice(1)})`;
          if (r === "stl") role = ` (${r.toUpperCase()})`;
        }
        return (
          invoice_data.push(`${project["id"]}/${project["role"]}`)
        );
      })}

    }
    else{
      invoice_data.push(this.state.currProject)
    }
    fetch(
      `${process.env.REACT_APP_TIMESHEET_DJANGO_URL}/timesheet/team-management-export/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body:JSON.stringify({"invoice_id":invoice_data, "emp_list":this.state.peoples.map(obj => obj[1]), "date":localStorage.getItem('date') , "is_all_selected":is_all})
      }
    ).then((res) => res.json()).then((res) => { 

      let csvArr = res.csvData
      if (csvArr.length == 0) {
        if (res.error.length) {
          this.setState({ is_loading: false, exportErr: true })
        } else {
          this.setState({ is_loading: false, noRecord: true })
        }
      }
      else {
        const csvString = this.getCSVString(csvArr);
        const csvBlob = this.getCSVBlob(csvString);
        this.serveCSVBlob(csvBlob);
        this.setState({ is_loading: false })
      }
    });

  }


  render() {
    console.log(this.state.rows);
    const { classes } = this.props;
    return (
      <div>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={this.state.noChange}
          autoHideDuration={5000}
          onClose={this.handleNoChange}
        >
          <Alert onClose={this.handleNoChange} severity="info">
            No changes done
          </Alert>
        </Snackbar>

        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={this.state.funcNonTech}
          autoHideDuration={5000}
          onClose={this.handleFuncNonTech}
        >
          <Alert onClose={this.handleFuncNonTech} severity="error">
            You cannot reject
          </Alert>
        </Snackbar>

        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={this.state.submitAlert}
          autoHideDuration={5000}
          onClose={this.handleSubmitAlert}
        >
          <Alert onClose={this.handleSubmitAlert} severity="success">
            Changes Recorded
          </Alert>
        </Snackbar>

        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={this.state.noRecord}
          autoHideDuration={5000}
          onClose={this.handleNoRecord}
        >
          <Alert onClose={this.handleNoRecord} severity="info">
            No records found
          </Alert>
        </Snackbar>

        {/* Snackbar for excel export error */}
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={this.state.exportErr}
          autoHideDuration={5000}
          onClose={this.handleExportErr}
        >
          <Alert onClose={this.handleExportErr} severity="error">
            Something went wrong
          </Alert>
        </Snackbar>

        <br />

        <Grid container spacing={3}>
          <Grid item xs>
            {localStorage.getItem("page") === "approver" && (
              <FormControl variant="filled" style={{ minWidth: 300 }}>
                <InputLabel htmlFor="invoice">Project</InputLabel>
                <Select
                  key="invoice"
                  inputProps={{
                    name: "invoice",
                    id: "invoice",
                  }}
                  value={this.state.currProject}
                  onChange={this.projectChange}
                  label="Project"
                >
                  <MenuItem value="-">
                    <em>All</em>
                  </MenuItem>
                  {this.state.projects.map((project) => {
                    var role = "";
                    if (project["flag"]) {
                      var r = project["role"].split("_")[1];
                      role = ` (${r[0].toUpperCase()}${r.slice(1)})`;
                      if (r === "stl") role = ` (${r.toUpperCase()})`;
                    }
                    return (
                      <MenuItem value={`${project["id"]}/${project["role"]}`}>
                        {`${project["name"]}${role}`}
                      </MenuItem>
                    );
                  })}
                  {/* {
                                        this.props.isStl &&
                                        <MenuItem value="83">DPA Internal Investments</MenuItem>
                                    } */}
                </Select>
              </FormControl>
            )}
          </Grid>
          <Grid item xs>
            <FormControl variant="filled" style={{ minWidth: 150 }}>
              <InputLabel htmlFor="type">Role</InputLabel>
              <Select
                key="type"
                inputProps={{
                  name: "type",
                  id: "type",
                }}
                value={this.state.currType}
                onChange={this.typeChange}
                disabled={this.state.is_tech || this.state.currProject === "-"}
                label="Role"
              >
                {(this.state.currProject.split("/")[1] === "is_stl" ||
                  this.state.currProject === "83") && (
                  <MenuItem value="0">All</MenuItem>
                )}
                {this.state.allTypes.map((type) => {
                  return <MenuItem value={type.id}>{type.role_name}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs>
            <FormControl variant="filled" style={{ minWidth: 300 }}>
              <InputLabel htmlFor="employee">Employee</InputLabel>
              <Select
                key="employee"
                inputProps={{
                  name: "employee",
                  id: "employee",
                }}
                onChange={this.dropdownChange}
                value={this.state.currEmp}
                disabled={this.state.peoples.length === 0}
                label="Employee"
              >
                <MenuItem value="-">-</MenuItem>
                {this.state.peoples.map((people) => {
                  return (
                    <MenuItem value={people[1]}>
                      {people[0]} - {people[1]}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>

          {/* Download Button */}
          {/* disabled={this.state.peoples.length === 0} */}
          <Grid item >
            <Button size="small" startIcon={<SaveAltIcon />} color='primary' title="Export Data" variant="contained" onClick={this.onDownloadClick} >Download</Button>
          </Grid>
        </Grid>

        <br />
        <br />

        {this.state.currEmp !== "-"  ? (
          <div>
            <Box display="flex" alignItems="center">
              <Box width="100%" mr={1}>
                <BorderLinearProgress
                  variant="determinate"
                  value={this.state.progress}
                />
              </Box>
              <Box minWidth={40}>
                <Typography variant="body2" color="textSecondary">
                  {`${Math.round(this.state.progress)}%`}
                </Typography>
              </Box>
            </Box>

            <br />
            <br />

            <MonthToggle
              currDate={this.state.currDate}
              code={this.state.currEmp}
              setData={this.fetchUniqueCombsUser}
              changeState={this.changeState}
            />

            <Paper style={{ width: "100%" }}>
              <TableContainer style={{ maxHeight: 800 }}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <StickyTableCell>
                        <MuiTableCell style={{ minWidth: "10ch" }}>
                          Sr. No.
                        </MuiTableCell>
                        <MuiTableCell style={{ minWidth: "20ch" }}>
                          Client
                        </MuiTableCell>
                        <MuiTableCell style={{ minWidth: "20ch" }}>
                          Project
                        </MuiTableCell>
                        <MuiTableCell style={{ minWidth: "20ch" }}>
                          Activity
                        </MuiTableCell>
                      </StickyTableCell>
                      {this.state.daysHeader}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.rows.map((row, index) => {
                      return (
                        <TableRow key={row.id}>
                          <StickyTableCell>
                            <MuiTableCell style={{ minWidth: "10ch" }}>
                              {index + 1}
                            </MuiTableCell>
                            <MuiTableCell style={{ minWidth: "20ch" }}>
                              {row.client}
                            </MuiTableCell>
                            <MuiTableCell style={{ minWidth: "20ch" }}>
                              {row.project}
                            </MuiTableCell>
                            <MuiTableCell style={{ minWidth: "20ch" }}>
                              {row.activity}
                            </MuiTableCell>
                          </StickyTableCell>
                          {row.days}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>

            <br />

            <Grid container spacing={3}>
              <Grid item sm={4}></Grid>
              <Grid item sm>
                <Button
                  onClick={this.handleSubmit}
                  variant="contained"
                  color="primary"
                  style={{ align: "center" }}
                >
                  Submit
                </Button>
              </Grid>
              <Grid item sm>
                <Button
                  onClick={this.clearSelection}
                  variant="contained"
                  color="secondary"
                  style={{ align: "center" }}
                >
                  Clear
                </Button>
              </Grid>
              <Grid item sm={4}></Grid>
            </Grid>
          </div>
        ) : (<SimpleMonthToggle currDate={this.state.currDate} changeState={this.changeState} />)}

        <Dialog
          open={this.state.openDialog}
          onClose={this.closeDialog}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          maxWidth="sm"
        >
          <DialogTitle id="form-dialog-title">Reason</DialogTitle>
          <DialogContent>
            <TextField
              multiline
              rows={4}
              variant="filled"
              id="comment"
              name="comment"
              label="Comment"
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeDialog} color="primary">
              Cancel
            </Button>
            <Button
              type="submit"
              onClick={this.handleReasonSubmit}
              color="primary"
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>

        {/* On Submit Loading Backdrop */}
        <Backdrop
          className={classes.backdropStyle}
          open={this.state.is_loading}
        >
          <CircularProgress
            color="primary"
            style={{
              marginRight: "50%",
              marginLeft: "40%",
              marginBottom: "20%",
            }}
          />
        </Backdrop>
      </div>
    );
  }
}

export default withStyles(styles)(Team1);
