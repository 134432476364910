import React, { useState, useEffect } from "react";

import MaterialTable from "@material-table/core";
import { Button, Snackbar,ButtonGroup,Tooltip,FormControl} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import MuiAlert from "@material-ui/lab/Alert";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import { useHistory as useHistoryHook } from "react-router-dom"; 
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import StopIcon from '@material-ui/icons/Stop';
import { Dialog, IconButton,DialogContent,DialogTitle,Grid,DialogActions}  from "@mui/material";
import TextField from '@mui/material/TextField';
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { format, parse, isValid } from 'date-fns';
import EditIcon from '@material-ui/icons/Edit';

import "./ClickupData.css";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    padding: 10,
  },
  input: {
    display: "none",
  },
  h2: {
    marginTop: 20,
    marginLeft: -100,
  },
  upload: {
    marginTop: 30,
    marginLeft: -20,
    marginBottom: 20,
  },
  buttons: {
    marginBottom: 20,
  },
  button: {
    margin: theme.spacing(1),
    width: "150px",
  },
  table: {
    minWidth: 650,
  },
  tableContainer: {
    maxHeight: 400,
    overflow: "auto",
  },
  tableCell: {
    padding: "4px 8px",
    lineHeight: "1.5",
    whiteSpace: "nowrap", 
    overflow: "hidden", 
    textOverflow: "ellipsis", 
  },
});

const ClickUpDataForm = ({ classes }) => {
  const history = useHistoryHook();
  const [departmentId, setDepartmentId] = useState(null);
  const [activeTab, setActiveTab] = useState("clickupform");
  const [projects, setProjects] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [loadingProjects, setLoadingProjects] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showSecondTab, setShowSecondTab] = useState(false);
  const [errors, setErrors] = useState({});
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tmCid, setTmCid] = useState("");
  const [tmPid, setTmPid] = useState("");
  const [tmStlId, setTmStlId] = useState("");
  const [projectName, setProjectName] = useState("");
  const [subprojectName, setSubprojectName] = useState("");
  const [projectType, setProjectType] = useState("");
  const [clientName, setClientName] = useState("");
  const [token,setToken]= useState("");
  const [listID,setListId]= useState("");
  const [data, setData] = useState([]);
  const projectOptions= process.env.REACT_APP_PROJECT_TYPE.split(",");

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [endDateWithoutBuffer, setEndDateWithoutBuffer] = useState("");
  // const projectOptions = process.env.REACT_APP_PROJECT_TYPE.split(",");
  const [isEditMode, setIsEditMode] = useState(false);
  const [editRowData, setEditRowData] = useState(null);

 const fetchData = async (pid) => {
  if (!pid || pid == undefined) return; 
  setUploading(true);
  try {
    const response = await fetch(`${process.env.REACT_APP_TIMESHEET_DJANGO_URL}/timesheet/get-clickup-project-data/?tm_pid=${pid}`, {
      method: 'GET',
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    const result = await response.json();
    if (response.ok) {
      const projectData = result.project_data;
      setData(projectData);  
    } else {
      setSnackOpen(true);
      setSnackbarText(result?.detail);
    }
  } catch (error) {
    setSnackbarOpen(true);
    setSnackbarMessage("Error fetching data");
    console.error('Error fetching data:', error);
  }
  finally{
    setUploading(false);
  }
};

  const fetchClickupData = async (listID, token) => {
    const url = `https://api.clickup.com/api/v2/list/${listID}/task?include_closed=true&subtasks=true&page=0`;
    const headers = {
      Authorization: `${token}`,
      'Content-Type': 'application/json',
    };
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: headers,
      });

      if (response.status !== 200) {
        setSnackbarOpen(true);
        setSnackbarMessage("Invalid token or ListID");
        return false;
      }
      return true;
    } catch (err) {
      setSnackbarOpen(true);
      setSnackbarMessage("Invalid token or ListID");
      console.error('Error fetching data:', err);
      return false;
    }
  };

 const API_URL= `${process.env.REACT_APP_TIMESHEET_DJANGO_URL}/timesheet/clickup-change-status/`

  const handleActivate = async (rowData) => {
    setLoading(true);
    try{
    const response= await fetch(API_URL, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        list_id: rowData.listId,   
        is_active: 1           
      }),
    })
     const result= await response.json();
      if (response.status === 200) {
        const updatedData = data.map((row) => 
          row.id === rowData.id ? { ...row, isActive: 1 } : row
        );
        setData(updatedData);
        setSnackbarsuccessOpen(true);
        setSnackbarsuccessMessage(result?.detail);
        
      } else {
        setSnackbarOpen(true);
        setSnackbarMessage(result?.detail);
      }
    }
    catch(error) {
      setSnackbarOpen(true);
      setSnackbarMessage("Error occured while updating status");
      console.error('Error:', error);
    }
    finally{
      fetchData(tmPid);
      setLoading(false);
    }
  };

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    let error = errors;

      if (name === "space") {
          if (value.trim() === "") {
              error.Space = "Space is required"; 
          } else if (value.length > 500) {
              error.Space = "Space should not be greater than 500 characters"; 
          }  else {
              error.Space = null; 
          }
      }
      if (name === "folder") {
        if (value.trim() === "") {
            error.Folder = "Folder is required"; 
        } else if (value.length > 500) {
            error.Folder = "Folder should not be greater than 500 characters"; 
        }  else {
            error.Folder = null; 
        }
    }
    if (name === "list") {
      if (value.trim() === "") {
          error.List = "List is required"; 
      } else if (value.length > 500) {
          error.List = "List should not be greater than 500 characters"; 
      }  else {
          error.List = null; 
      }
    }
    if (name === "token") {
      if (value.trim() === "") {
          error.Token = "Token is required"; 
      } else if (value.length > 100) {
          error.Token = "Token should not be greater than 100 characters"; 
      }  else {
          error.Token = null; 
      }
    }
    if (name === "listid") {
      if (value.trim() === "") {
          error.ListId = "ListId is required"; 
      } else if (value.length > 100) {
          error.ListId = "ListId should not be greater than 100 characters"; 
      }  else {
          error.ListId = null; 
      }
    }

    if(name === "projectType"){
      if (value && value.trim() === "") {
        error.ProjectType = "Project Type is required"; 
      }
      else {
        error.ProjectType = null; 
    } 
    }

    if (name === "projectType") {
      if (!value || value.trim() === "") {
        error.projectType = "Project Type is required"; 
      } else {
        error.projectType = null;
      }
      if (value !== "Operation") {
        error.startDate = null;
        error.endDate = null;
        error.endDateWithoutBuffer = null;
      }
    }
    if (projectType === "Operation") {
      const parseDate = (dateString) => {
        let parsedDate = parse(dateString, 'dd/MM/yyyy', new Date());
        if (!isValid(parsedDate)) {
          parsedDate = parse(dateString, 'yyyy-MM-dd', new Date());
        }
        
        return parsedDate;
      };
  
      if (name === "startDate") {
        error.startDate = !value
          ? "Start Date is required"
          : !isValid(parseDate(value))
          ? "Invalid Date Format"
          : null;
      }
  
      if (name === "endDate") {
        error.endDate = !value
          ? "End Date is required"
          : !isValid(parseDate(value))
          ? "Invalid Date Format"
          : null;
      }
  
      if (name === "endDateWithoutBuffer") {
        error.endDateWithoutBuffer = !value
          ? "End Date Without Buffer is required"
          : !isValid(parseDate(value))
          ? "Invalid Date Format"
          : null;
      }
    
    }
  
    
    setErrors({
        ...error,
    });
};

  const handleDeactivate = async (rowData) => {
    setLoading(true);
    try{
      const response= await fetch(API_URL, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          list_id: rowData.listId,   
          is_active: 0           
        }),
      })
      const result= await response.json();
    
      if (response.status === 200) {
        const updatedData = data.map((row) => 
          row.id === rowData.id ? { ...row, isActive: 0 } : row
        );
        setData(updatedData);
        setSnackbarsuccessOpen(true);
        setSnackbarsuccessMessage(result?.detail);
      } else {
        setSnackbarOpen(true);
        setSnackbarMessage(result?.detail);
      }
    }
    catch(error) {
      setSnackbarOpen(true);
      setSnackbarMessage("Error occured while updating status");
      console.error('Error:', error);
    }
    finally{
      fetchData(tmPid);
      setLoading(false);
    }
  };

  const columns = [
    { 
      title: 'TM PID', 
      field: 'TM_PID', 
      headerStyle: {textAlign: 'left'},
      cellStyle: { textAlign: 'left' },
      minWidth: 100
    },
    { 
      title: 'TM STL ID', 
      field: 'TM_STL_ID',
      cellStyle: { textAlign: 'left' },
      minWidth: 100
    },
    { 
      title: 'Space', 
      field: 'space',
      cellStyle: { textAlign: 'left' },
      minWidth: 100,
      maxWidth: 500
    },
    { 
      title: 'Folder', 
      field: 'folder',
      cellStyle: { textAlign: 'left' },
      minWidth: 100,
      maxWidth: 500
    },
    { 
      title: 'List', 
      field: 'list',
      cellStyle: { textAlign: 'left' },
      minWidth: 100,
      maxWidth: 500
    },
    { 
      title: 'Project Type', 
      field: 'projectType',
      cellStyle: { textAlign: 'left' },
      minWidth: 100,
      maxWidth: 500
    },
    
    { 
      title: 'List ID', 
      field: 'listId',
      cellStyle: { textAlign: 'left' },
      minWidth: 100 
    },
    { 
      title: 'Is Active', 
      field: 'isActive',
      cellStyle: { textAlign: 'left' },
      minWidth: 100
    },
    {
      title: 'Action',
      field: 'actions',
      render: (rowData) => (
        <div>
          <Tooltip title="Edit" arrow>
            <IconButton onClick={() => handleEdit(rowData)} color="default">
              <EditIcon style={{ color: "#1976D2" }} /> {/* Blue color */}
            </IconButton>
          </Tooltip>
          
          {rowData.isActive ? (
            <Tooltip title="Deactivate" arrow>
            <IconButton onClick={() => handleDeactivate(rowData)} color="secondary" fontsize='large'>
              <StopIcon style={{ color: '#FF3D00' }} />
            </IconButton>
          </Tooltip>
          ) : (
            <Tooltip title="Activate" arrow>
              <IconButton onClick={() => handleActivate(rowData)} color="primary" fontSize='large'>
                <PlayArrowIcon style={{ color: '#4CAF50 ' }} />
              </IconButton>
            </Tooltip>
          )}
          
        </div>
      ),
      cellStyle: { textAlign: 'left' },
      minWidth: 50
    }
  ];
  // Error Snackbar state
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  //Snackbar for no changes
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackbarText, setSnackbarText] = useState("");

  // Success Snackbar state
  const [snackbarsucessOpen, setSnackbarsuccessOpen] = useState(false);
  const [snackbarsuccessMessage, setSnackbarsuccessMessage] = useState("");

  const [uploading, setUploading] = useState(false); // Loader for table data
  const [loadSubmit, setloadSubmit] = useState(false); //Loader for file submit

  // Fetch projects and update state

  const handleClickOpen = () => {
    setOpen(true); setIsEditMode(false);
  }
  const handleClose = () => {
    setOpen(false);
    setIsEditMode(false);
    setErrors({});
    setProjectName("");
    setClientName("");
    setSubprojectName("");
    setListId("");
    setToken("");
    setProjectType("");
    setStartDate("");
    setEndDate("");
    setEndDateWithoutBuffer("");
  }
  
  const handleSubmit = async () => {
    setloadSubmit(true);
    const isDataFetched = await fetchClickupData(listID, token);
    if (!isDataFetched) {
      setloadSubmit(false);
      setOpen(false);
      return; 
    }
     const formData = {
      cid: tmCid,
      pid: tmPid,
      stl_id: tmStlId,
      client_name: clientName,
      project_name: projectName,
      project_type: projectType,
      subproject_name: subprojectName,
      list_id: listID,
      token: token,
      is_active: 1
    };
    if (projectType === "Operation") {
      formData.start_date = startDate;
      formData.end_date = endDate;
      formData.end_date_without_buffer = endDateWithoutBuffer;
    }
    const response = await fetch(`${process.env.REACT_APP_TIMESHEET_DJANGO_URL}/timesheet/project-data-submit/`, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(formData),
    })
   
    try{
      const result = await response.json();
      if (response.status === 200) {
        setSnackbarsuccessOpen(true);
        setSnackbarsuccessMessage(result?.message);
        setOpen(false);
        fetchData(tmPid);
      } else {
        setSnackbarOpen(true);
        setSnackbarMessage(result?.message);
      }
    }
    catch(error) {
      console.error('Error while submitting Data:', error);
      setSnackbarOpen(true);
      setSnackbarMessage("Error while submitting Data !");  
    }
    finally{
      setOpen(false);
      setloadSubmit(false);
      setProjectName("");
      setClientName("");
      setSubprojectName("");
      setListId("");
      setToken("");
      setProjectType("");
    }
  };

  const handleViewTabClick = () => {
    setActiveTab("clickup2");
    history.push("/estimate-data");
  };

  const handleClickUpFormClick = () => {
    setActiveTab("clickupform");
  };

  useEffect(() => {
    fetchDepartmentData();
    handleProjectDetails();
  }, []);

  // const hasEmptyFields = () => {
  //   return [projectName, subprojectName, clientName, token, projectType].some(
  //     (value) => value == null || value.trim() === ""
  //   );
  // };
  const hasEmptyFields = () => {
    // Base fields
    const baseFields = [projectName, subprojectName, clientName, token, projectType];
    const baseEmptyCheck = baseFields.some(
      (value) => value == null || value.trim() === ""
    );
  
    if (projectType === "Operation") {
      const dateFields = [startDate, endDate, endDateWithoutBuffer];
      const dateEmptyCheck = dateFields.some(
        (value) => value == null || value.trim() === ""
      );
      return baseEmptyCheck || dateEmptyCheck;
    }
    return baseEmptyCheck;
  };
    
  const fetchDepartmentData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_TIMESHEET_DJANGO_URL}/user-view`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      const data = await response.json();
      setDepartmentId(data.department_id);
      setShowSecondTab(data.department_id === 19);
    } catch (error) {
      setSnackbarOpen(true);
      setSnackbarMessage(error.message);
    }
  };

  const fetchProjects = async () => {
    try {
      setLoadingProjects(true);
      const response = await fetch(
        `${process.env.REACT_APP_TIMESHEET_DJANGO_URL}/master/clickup-project-details`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const results = await response.json();
      setProjects(results);
    } catch (error) {
      setSnackbarOpen(true);
      setSnackbarMessage(error.message);
    } finally {
      setLoadingProjects(false);
    }
  };

  //Calling fetchprojects on initial mount
  useEffect(() => {
    handleProjectDetails();
  }, []);

  const handleProjectDetails = async () => {
    setIsLoading(true);
    try {
      await fetchProjects();
    } catch (error) {
      setSnackbarOpen(true);
      setSnackbarMessage(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleCloseSnack = () => {
    setSnackOpen(false);
  };

  const handleSuccessSnackbar = () => {
    setSnackbarsuccessOpen(false);
  };

  const handleChangeProjects = (event, newValue) => {
    setSelectedProjects(newValue);
    if(newValue && newValue != undefined){
      setTmCid(newValue.client_id);
      setTmPid(newValue.id);
      setTmStlId(newValue.stl_employee_code);
      fetchData(newValue.id);
    }
    else{
      setTmCid("");
      setTmPid("");
      setTmStlId("");
      setProjectName("");
      setClientName("");
      setSubprojectName("");
      setListId("");
      setToken("");
    }
    setData([]);
  };

  const hasErrors = () => {
    const hasFieldErrors = Object.values(errors).some((error) => error !== null);
    
    if (projectType === "Operation") {
      const hasDateErrors = 
        !startDate || startDate.trim() === "" ||
        !endDate || endDate.trim() === "" ||
        !endDateWithoutBuffer || endDateWithoutBuffer.trim() === "";
      
      return hasFieldErrors || hasDateErrors;
    }
    
    return hasFieldErrors;
  }

const fetchEditDetails = async (rowData) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_TIMESHEET_DJANGO_URL}/timesheet/get-clickup-record-data/?list_id=${rowData.listId}`, 
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error('Failed to fetch edit details');
    }

    const result = await response.json();
    setEditRowData(result);
    setIsEditMode(true);
    setListId(result.project_data.list_id);
    setToken(result.project_data.token);
    setClientName(result.project_data.space);
    setProjectName(result.project_data.folder);
    setSubprojectName(result.project_data.list);
    setProjectType(result.project_data.projectType);
    
    if (result.project_data.projectType === "Operation") {
      setStartDate(result.project_data.startDate || null);
      setEndDate(result.project_data.endDate || null);
      setEndDateWithoutBuffer(result.project_data.endDateWithoutBuffer || null);
    }
  } catch (error) {
    console.error("Error fetching edit details:", error);
    setSnackbarOpen(true);
    setSnackbarMessage("Failed to fetch edit details");
    throw error;  
  }
};

const handleEdit = (rowData) => {
  setLoading(true);
  fetchEditDetails(rowData)
    .then(() => {
      setOpen(true);
      setLoading(false);
    })
    .catch((error) => {
      console.error("Error in edit process:", error);
      setSnackbarOpen(true);
      setSnackbarMessage("Failed to fetch edit details");
      setLoading(false);
    });
};
  

  return (
    <div className={classes.root}>
      <Typography
        variant="h2"
        sx={{
          fontFamily: "calibri",
          fontSize: "2rem",
          fontWeight: "bold",
          color: "black",
          textAlign: "center",
          marginTop: "2px",
          margin: "8px 0",
          marginBottom: "2px",
          padding: "5px",
        }}
      >
        API Scheduler
      </Typography>
      <div className={classes.tabs} style={{ marginBottom: 20 }}>
        <div
          className={classes.tabs}
          style={{
            padding: "10px",
            display: "flex",
            justifyContent: "center",
            gap: "7px",
          }}
        >
           <ButtonGroup
            color="primary"
            aria-label="small outlined button group"
            size="large"
           >

          {showSecondTab && (
            <Button
              //style={{ padding: "10px" }}
              variant={activeTab === "clickup2" ? "contained" : "outlined"}
              color="primary"
              onClick={handleViewTabClick}
            >
              Project Estimate
            </Button>
          )}
            <Button
              //style={{ padding: "10px" }}
              variant={activeTab === "clickupform" ? "contained" : "outlined"}
              color="primary"
              onClick={handleClickUpFormClick}
            >
            API Scheduler
            </Button>
            </ButtonGroup>
        </div>
      </div>

      {isLoading ? (
        <div className="backdrop">
          <div className="loader"></div>
        </div>
      ) : null}

      {loading ? (
        <div className="backdrop">
          <div className="loader"></div>
        </div>
      ) : null} 
    
      {uploading ? (
        <div className="backdrop">
          <div className="loader"></div>
        </div>
      ) : null}

      <div className="project-details" style={{ display: "flex", alignItems: "center", gap: "10px", marginTop: "15px" }}>
        <Autocomplete
          options={projects}
          size="medium"
          style={{ width: 400, marginLeft: 400, marginTop: 15, alignContent: 'center' }}
          noOptionsText={"No records found"}
          getOptionLabel={(option) => option.display_name}
          value={selectedProjects}
          onChange={handleChangeProjects}
          loading={loadingProjects}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Select Project"
            />
            
          )}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                label={option.display_name}
                {...getTagProps({ index })}
                key={option.id} // Assuming each option has a unique `id`
                style={{ marginRight: 4, marginBottom: 4 }} // Adjust as needed
              />
            ))
          }
          sx={{
            width: 600,
            marginLeft: 400,
            marginTop: 30,
            '& .MuiAutocomplete-tag': {
              marginRight: 4,
              marginBottom: 4,
            },
            '& .MuiAutocomplete-paper': {
              maxHeight: 200, // Adjust height as needed
              overflowY: 'auto',
            },
          }}
        />
     
        {/* Button to open dialog */}
        <Button variant="contained" color="primary" onClick={handleClickOpen} style={{marginTop: '20px'}} disabled= {!selectedProjects || (selectedProjects && selectedProjects.length === 0)}> 
        Create Entry
      </Button>

      {/* Dialog Box */}
      <Dialog open={open} onClose={handleClose} width="800px" maxWidth="md">
        <DialogTitle>Schedular Details</DialogTitle>
        <DialogContent>
          {/* Fields in a grid layout */}
          <Grid container spacing={2} style={{ marginTop: 20, width: 800}}>
            <Grid item xs={6}>
                <TextField
                  label="List ID"
                  variant="outlined"
                  name="listid"
                  error={Boolean(errors.ListId)}
                  onBlur={handleChangeInput}
                  fullWidth
                  required
                  value={listID}
                  InputProps={{
                    style: {
                      borderColor: errors.ListId ? "red" : undefined,
                    },
                  }}
                  onChange={(e) => {
                    setListId(e.target.value)
                    handleChangeInput(e)
                  }}
                />
                <Typography
                  mt={1}
                  style={{
                    fontSize: "12px",
                    color: errors.ListId ? "red" : "black",
                    textAlign: "left",
                  }}
                >
                {errors.ListId && <span>{errors.ListId}</span>}
              </Typography>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="API Auth Token"
                  name="token"
                  onBlur= {handleChangeInput}
                  error= {Boolean(errors.Token)}
                  variant="outlined"
                  fullWidth
                  required
                  value={token}
                  InputProps={{
                    style: {
                      borderColor: errors.Token ? "red" : undefined,
                    },
                  }}
                  onChange={(e) => {
                    setToken(e.target.value)
                    handleChangeInput(e)
                  }}
                />
                <Typography
                  mt={1}
                  style={{
                    fontSize: "12px",
                    color: errors.Token ? "red" : "black",
                    textAlign: "left",
                  }}
                >
                {errors.Token && <span>{errors.Token}</span>}
              </Typography>
              </Grid>
            <Grid item xs={6}>
              <TextField
                label="TM CID"
                variant="outlined"
                disabled
                fullWidth
                required
                value={tmCid}
                onChange={(e) => setTmCid(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="TM PID"
                variant="outlined"
                disabled
                fullWidth
                required
                value={tmPid}
                onChange={(e) => setTmPid(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="TM STL ID"
                variant="outlined"
                disabled={true}
                fullWidth
                required
                value={tmStlId}
                onChange={(e) => setTmStlId(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Space"
                name= "space"
                variant="outlined"
                onBlur={handleChangeInput}
                fullWidth
                required
                value={clientName}
                onChange={(e) => {
                  setClientName(e.target.value);
                  handleChangeInput(e);            
                }}
                error={Boolean(errors.Space)}
                InputProps={{
                  style: {
                    borderColor: errors.Space ? "red" : undefined,
                  },
                }}
              />
              <Typography
                  mt={1}
                  style={{
                    fontSize: "12px",
                    color: errors.Space ? "red" : "black",
                    textAlign: "left",
                  }}
                >
                {errors.Space && <span>{errors.Space}</span>}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Folder"
                name="folder"
                onBlur={handleChangeInput}
                variant="outlined"
                fullWidth
                required
                value={projectName}
                error= {Boolean(errors.Folder)}
                InputProps={{
                  style: {
                    borderColor: errors.Folder ? "red" : undefined,
                  },
                }}
                onChange={(e) => {
                  setProjectName(e.target.value);
                  handleChangeInput(e);
                }
               }  
              />
              <Typography
                  mt={1}
                  style={{
                    fontSize: "12px",
                    color: errors.Folder ? "red" : "black",
                    textAlign: "left",
                  }}
                >
                {errors.Folder && <span>{errors.Folder}</span>}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="List"
                name="list"
                variant="outlined"
                fullWidth
                onBlur={handleChangeInput}
                required
                value={subprojectName}
                error= {Boolean(errors.List)}
                InputProps={{
                  style: {
                    borderColor: errors.List ? "red" : undefined,
                  },
                }}
                onChange={(e) => {
                  setSubprojectName(e.target.value)
                  handleChangeInput(e);
                }}
              />
               <Typography
                  mt={1}
                  style={{
                    fontSize: "12px",
                    color: errors.List ? "red" : "black",
                    textAlign: "left",
                  }}
                >
                {errors.List && <span>{errors.List}</span>}
              </Typography>
            </Grid>
            
            <Grid item xs={12}>
              <FormControl fullWidth required
              error={Boolean(errors.ProjectType)}>
                <Autocomplete
                  options={projectOptions}
                  value={projectType}
                  onBlur={handleChangeInput}
                  onChange={(event, newValue) => {
                    setProjectType(newValue);
                    handleChangeInput({
                      target: { name: "projectType", value: newValue },
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={
                        <>
                          Project Type <span>*</span>
                        </>
                      }
                      variant="outlined"
                    />
                  )}
                  />
                  <Typography
                  mt={1}
                  style={{
                    fontSize: "12px",
                    color: errors.ProjectType ? "red" : undefined,
                    textAlign: "left",
                  }}
                  >
                  {errors.ProjectType && <span>{errors.ProjectType}</span>}
                  </Typography>
                  {projectType === "Operation" && (
                  <Grid
                    container
                    spacing={2}
                    marginTop={2}
                    justifyContent="space-between"
                    alignItems="stretch"
                  >
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                      {/* <TextField
                        label="Start Date *"
                        type="date"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                      /> */}
                      <KeyboardDatePicker
                        inputVariant="filled"
                        margin="normal"
                        name="startDate"
                        id="date-picker-start"
                        label="Start Date *"
                        autoOk={true}
                        format="dd/MM/yyyy"
                        value={startDate}
                        onChange={(date) => {
                          if (date && isValid(date)) {
                            const formattedDate = format(date, 'yyyy-MM-dd');
                            setStartDate(formattedDate);
                            handleChangeInput({
                              target: { name: 'startDate', value: formattedDate },
                            });
                          } else {
                            setStartDate('');
                            handleChangeInput({
                              target: { name: 'startDate', value: '' },
                            });
                          }
                        }}
                        onBlur={(e) => handleChangeInput(e)} 
                        error={Boolean(errors.startDate)}
                        helperText={errors.startDate || ""} 
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          style: {
                            height: 60,
                            borderColor: errors.startDate ? "red" : undefined,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                      {/* <TextField
                        label="End Date *"
                        type="date"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                      /> */}
                      <KeyboardDatePicker
                        inputVariant="filled"
                        margin="normal"
                        name="endDate"
                        id="date-picker-end"
                        label="End Date *"
                        autoOk={true}
                        format="dd/MM/yyyy"
                        value={endDate}
                        onChange={(date) => {
                          if (date && isValid(date)) {
                            const formattedDate = format(date, 'yyyy-MM-dd');
                            setEndDate(formattedDate);
                            handleChangeInput({
                              target: { name: 'endDate', value: formattedDate },
                            });
                          } else {
                            setEndDate('');
                            handleChangeInput({
                              target: { name: 'endDate', value: '' },
                            });
                          }
                        }}                        
                        onBlur={(e) => handleChangeInput(e)} 
                        error={Boolean(errors.endDate)}
                        helperText={errors.endDate || ""} 
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          style: {
                            height: 60,
                            borderColor: errors.endDate ? "red" : undefined,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                      {/* <TextField
                        label="End Date without Buffer *"
                        type="date"
                        value={endDateWithoutBuffer}
                        onChange={(e) =>
                          setEndDateWithoutBuffer(e.target.value)
                        }
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                      /> */}
                      <KeyboardDatePicker
                        inputVariant="filled"
                        margin="normal"
                        name="endDateWithoutBuffer"
                        id="date-picker-end-buffer"
                        label="End Date Without Buffer *"
                        autoOk={true}
                        format="dd/MM/yyyy"
                        value={endDateWithoutBuffer}
                        onChange={(date) => {
                          if (date && isValid(date)) {
                            const formattedDate = format(date, 'yyyy-MM-dd');
                            setEndDateWithoutBuffer(formattedDate);
                            handleChangeInput({
                              target: { name: 'endDateWithoutBuffer', value: formattedDate },
                            });
                          } else {
                            setEndDateWithoutBuffer('');
                            handleChangeInput({
                              target: { name: 'endDateWithoutBuffer', value: '' },
                            });
                          }
                        }}
                        onBlur={(e) => handleChangeInput(e)} 
                        error={Boolean(errors.endDateWithoutBuffer)}
                        helperText={errors.endDateWithoutBuffer || ""} 
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          style: {
                            height: 60,
                            borderColor: errors.endDateWithoutBuffer ? "red" : undefined,
                          },
                        }}
                      />
                    </Grid>
                    </MuiPickersUtilsProvider>
                  </Grid>
                )}
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>

        {loadSubmit ? (
        <div className="backdrop">
          <div className="loader"></div>
        </div>
      ) : null}

      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        {/* <Button
          onClick={handleSubmit}
          color="primary"
          disabled={hasEmptyFields() || hasErrors()}
          variant="contained"
        >
          Submit
        </Button> */}
        <Button
        onClick={handleSubmit}
        color="primary"
        disabled={hasEmptyFields() || hasErrors()}
        variant="contained"
        >
        {isEditMode ? 'Update' : 'Submit'}
        </Button>
      </DialogActions>
      </Dialog>
      </div>
      {/* Snackbar for errors */}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
      >
        <MuiAlert onClose={handleCloseSnackbar} severity="error" variant="filled">
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={snackOpen}
        autoHideDuration={5000}
        onClose={handleCloseSnack}
      >
        <MuiAlert onClose={handleCloseSnack} severity="info" variant="filled">
          {snackbarText}
        </MuiAlert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={snackbarsucessOpen}
        autoHideDuration={5000}
        onClose={handleSuccessSnackbar}
      >
        <MuiAlert onClose={handleSuccessSnackbar} severity="success" variant="filled">
          {snackbarsuccessMessage}
        </MuiAlert>
      </Snackbar>
     
    
     <div style={{ maxHeight: '300px', overflowY: 'auto', marginTop: '20px' }}> {/* Max height for scrolling */}
      <MaterialTable 
        title="Project Schedular Details"
        columns={columns}
        data={data}
        options={{
          filtering: false,
          paging: false,
          emptyRowsWhenPaging: false,
          headerStyle: {
            backgroundColor: "#2474FC",
            color: "#FFFFFF",
          },
          rowStyle: {
            backgroundColor: "#FFFFFF",
          }
        }}
     />
    </div>
    </div>
  );
};

export default withStyles(styles)(ClickUpDataForm);
